/* eslint-disable import/no-anonymous-default-export */
import useUserRole from 'hooks/useUserRole';
import Storage from '../../utils/storage';
import { TYPES } from '../actions';
import _ from 'lodash';
import { STORAGE } from '../../utils/constant';

const initialState = {
  status: false,
  error: null,
  token: null,
  data: null,
  loading: false,
  logoutProcess: false,
  category: null,
  listSubscriber: [],
  listFans: [],
  commentDetail: [],

  listBlock: [],
  listBlockPagination: {},
  loadingListBlockMore: false,

  listBuyer: [],
  listBuyerPagination: {},

  listRankingBuyer: [],
  loadingListContent: false,
  listContent: [],
  checkToken: 'URL_VALID',
  success: false,
  listCountries: [],
  listCountryLoading: false,
  userProfile: null,
  stats: null,
  text: '',
  userListFollowing: [],
  creatorListPackage: [],
  creatorPackageDetail: {},
  fanListBookmark: [],
  fanListBookmarkPagination: {},
  loadingFollow: false,
  fanMessageDetailCreator: [],
  fanDonateCoin: null,
  tokenExist: false,
  urlExpired: false,
  signUpFailed: false,
  signUpSuccess: false,
  creatorUpdateProfileFailed: false,
  creatorUpdateProfileSuccess: false,
  creatorUpdateProfileBasicFailed: false,
  creatorUpdateProfileBasicSuccess: false,
  requestApproveSuccess: false,
  changeEmailStep1: null,
  changeEmailStep2: null,
  changeEmailConfim1Failed: null,
  changeEmailConfirmStep1: null,
  changeEmailConfirmStep2: null,
  changeEmailConfim2Failed: null,
  listFanViewedPosts: [],
  listFanViewed: [],
  creatorIdPosts: [],
  pagination: {},
  anotherFanDetail: null,
  previousUserFollow: null,
  fanListPackages: [],
  fanListPackagesPagination: {},

  fanListLikedPost: [],
  fanListLikedPostPagination: {},
  fanListLikedPostNoGroupBy: [],

  loadingRecommended: false,
  recommendedData: [],

  loadingListPostRecommended: false,
  listPostrecommendedData: [],

  searchShortVideo: [],
  isLoadDataSearchShort: true,
  searchShortVideoPagination: {},

  searchUser: [],
  searchUserPagination: {},

  searchHashtag: [],
  searchHashtagPagination: {},

  searchHashtagPosts: [],
  searchHashtagPostsPagination: {},

  isLoadProfile: false,
  dataUser: {},
  userPoints: 0,
  listSaleContents: [],
  listSaleContentsPagination: {},
  listContentBuyers: [],
  listContentBuyersPagination: {},
  isLoadingFollow: false,
  initPageSeachUser: 0,
  idSearchUser: {},
  initPageBuyer: 0,
  idBuyer: {},
  initPageBuyed: 0,
  idBuyed: {},
  idSubscribe: {},
  initPageSubscribe: 0,
  idLike: {},
  idScrollBackFollowing: null,
  idScrollBackFollower: null,
  paginationFollower: {},
  paginationFollowing: {},
  idScrollBackSubcriber: {},
  idBlock: {},
  idRankingBuy: null,
  loadingBlock: false,
  paginationRanking: 0,
  paginationSubcriber: {},
  initPageBlock: 0,
  initPageSub: 0,
  initPageLike: 0,
  initPageBook: 0,
  idFavorite: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_STATS_SUCCESS:
      return {
        ...state,
        stats: action.data.data,
        loading: false,
      };
    case TYPES.GET_CREATOR_PACKAGE_DETAIL_FAILED:
      return {
        ...state,
        loading: false,
        status: 'package_notfound',
      };
    case TYPES.EDIT_EMAIL_SUCCESS:
    case TYPES.REVIEW_IMAGE_SUCCESS:
    case TYPES.REQUEST_LEAVE_SUCCESS:
    case TYPES.USER_REPORT_SUCCESS:
    case TYPES.CREATOR_PACKAGE_FAILED:
    case TYPES.UPDATE_CREATOR_PACKAGE_FAILED:
      return {
        ...state,
        status: false,
        error: action.data.error,
        loading: false,
      };
    case TYPES.LOGOUT_SUCCESS: {
      return {
        ...state,
        status: false,
        error: action.data.error,
        loading: false,
      };
    }
    case TYPES.DELETE_CREATOR_PACKAGE_FAILED:
    case TYPES.CREATOR_LIST_PACKAGE_FAILED:
      return {
        ...state,
        loading: false,
        status: 'failed',
      };
    case TYPES.CREATOR_PACKAGE:
    case TYPES.UPDATE_CREATOR_PACKAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        status: 'success',
      };

    case TYPES.DELETE_CREATOR_PACKAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        status: 'success',
      };
    case TYPES.EDIT_EMAIL_REQUEST:
    case TYPES.REVIEW_IMAGE_REQUEST:
    case TYPES.REQUEST_LEAVE_REQUEST:
    case TYPES.GET_STATS_REQUEST:
    case TYPES.USER_REPORT_REQUEST:
    case TYPES.GET_DETAIL_FAN_REQUEST:
    case TYPES.FOLLOW_OR_UN_FOLLOW_USER_REQUEST:
      return {
        ...state,
        isLoadingFollow: true,
      };
    case TYPES.LOGOUT_REQUEST: {
      return {
        ...state,
        loading: true,
        logoutProcess: true,
      };
    }
    case TYPES.BLOCK_OR_UNBLOCK_USER_REQUEST:
      return {
        ...state,
        loadingBlock: true,
      };
    case TYPES.CREATOR_REQUEST:
    case TYPES.UPDATE_CREATOR_PACKAGE_REQUEST:
    case TYPES.BLOCK_OR_UNBLOCK_IN_PROFILE_FAN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TYPES.DELETE_CREATOR_PACKAGE_REQUEST:
    case TYPES.GET_CREATOR_PACKAGE_DETAIL_REQUEST:
    case TYPES.CREATOR_LIST_PACKAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TYPES.CREATOR_PACKAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.CREATOR_PACKAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        status: 'success',
      };
    // case TYPES.CREATOR_PACKAGE_FAILED:
    //   return {
    //     ...state,
    //     status: false,
    //     error: action.data.error,
    //     loading: false,
    //   };

    case TYPES.GET_COUNTRY_FAILED:
      return {
        ...state,
        listCountryLoading: false,
        error: action.data.error,
      };
    case TYPES.GET_COUNTRY_SUCCESS:
      return {
        ...state,
        listCountryLoading: false,
        listCountries: action.data.data.data,
      };
    case TYPES.GET_DETAIL_FAN_SUCCESS:
      return {
        ...state,
        loading: false,
        userProfile: action.data.data,
        success: true,
      };
    case TYPES.FOLLOW_OR_UN_FOLLOW_USER_SUCCESS:
      state.searchUser = state.searchUser.map((item) =>
        +item.id === +action.data.id
          ? { ...item, is_followed: action.data.data.is_followed }
          : item
      );

      state.anotherFanDetail = {
        ...state.anotherFanDetail,
        is_followed: action.data.data.is_followed,
      };
      state.listFans = state.listFans.map((item) =>
        +item.id === +action.data.id
          ? { ...item, status_follow: action.data.data.status_follow }
          : item
      );

      let newUserListFollowing = [];
      if (action.data.flg) {
        if (action.data.data.is_followed === 0) {
          const cloneInitArr = [...state.userListFollowing];
          state.previousUserFollow = state.userListFollowing.filter(
            (item) => item?.id === action?.data?.id
          )?.[0];
          newUserListFollowing = cloneInitArr.filter((item) => item?.id !== action?.data?.id);
        } else {
          newUserListFollowing = [state.previousUserFollow, ...state.userListFollowing];
        }
      } else {
        state.userListFollowing = state.userListFollowing.map((item) =>
          +item.id === +action.data.id
            ? { ...item, is_followed: action.data.data.is_followed }
            : item
        );
      }

      if (state.fanListBookmark) {
        state.fanListBookmark = state.fanListBookmark.map((item) =>
          +item.user_id === +action.data.id
            ? { ...item, is_followed: action.data.data.is_followed }
            : item
        );
      }

      return {
        ...state,
        anotherFanDetail: state.anotherFanDetail,
        userListFollowing: action.data.flg ? newUserListFollowing : state.userListFollowing,
        listFans: state.listFans,
        success: true,
        isLoadingFollow: false,
      };

    case TYPES.EDIT_COMMENT_DETAILS_SUCCESS:
      state.fanListBookmark = state.fanListBookmark.map((item) => {
        if (item?.latest_comment?.id === action.data.data.id) {
          const newItem = { ...item };
          newItem.latest_comment.content = action.data.data.content;
          newItem.latest_comment.created_at = action.data.data.updated_at;
          newItem.latest_comment.is_edited = 1;
          return newItem;
        }
        return item;
      });

      return {
        ...state,
        statusEditComment: true,
        loading: false,
      };

    case TYPES.POST_COMMENT_DETAILS_SUCCESS:
      state.fanListBookmark = state.fanListBookmark.map((item) => {
        if (+item?.id === +action?.data?.data?.post_id) {
          const newItem = { ...item };
          ++newItem.number_comment;
          if (newItem.latest_comment) {
            newItem.latest_comment.id = action.data.data.id;
            newItem.latest_comment.content = action.data.data.content;
            newItem.latest_comment.created_at = action.data.data.created_at;
            newItem.latest_comment.avatar = action.data.data.avatar;
            newItem.latest_comment.account_name = action.data.data.account_name;
            newItem.latest_comment.user_id = action.data.data.user_id;
            newItem.latest_comment.number_like = action.data.data.number_like;
            newItem.latest_comment.is_liked = action.data.data.is_liked;
            newItem.latest_comment.number_comment = action.data.data.number_comment;
          } else {
            newItem.latest_comment = {
              id: action.data.data.id,
              avatar: action.data.data?.avatar,
              user_id: action.data.data?.user_id,
              account_name: action.data.data?.account_name,
              content: action.data.data.content,
              created_at: action.data.data.created_at,
              number_like: action.data.data.number_like,
              is_liked: action.data.data.is_liked,
            };
          }
          return newItem;
        } else {
          return item;
        }
      });
      return {
        ...state,
      };

    case TYPES.DELETE_COMMENT_DETAILS_SUCCESS:
      state.fanListBookmark = state.fanListBookmark.map((item) => {
        if (+item.latest_comment?.id === action.data.data.payload) {
          const newItem = { ...item };
          newItem.latest_comment = null;
          newItem.number_comment = --newItem.number_comment;
          return newItem;
        } else return item;
      });

      return {
        ...state,
      };

    case TYPES.LIKE_SUCCESS:
      state.fanListBookmark = state.fanListBookmark.map((item) => {
        if (+item.id === +action.data.data.post_id) {
          const newItem = { ...item };
          if (newItem.is_liked === 1) {
            newItem.is_liked = 0;
            --newItem.number_like;
          } else {
            newItem.is_liked = 1;
            ++newItem.number_like;
          }
          return newItem;
        } else return item;
      });
      return {
        ...state,
      };
    case TYPES.CREATOR_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case TYPES.BLOCK_OR_UNBLOCK_USER_SUCCESS:
      //Handle UnBlock User
      state.listBlock =
        action.data.data.is_blocked === 0 &&
        state.listBlock.filter((item) => item.id !== action.data.id);

      return {
        ...state,
        loading: false,
        success: true,
        loadingBlock: false,
      };
    case TYPES.BLOCK_OR_UNBLOCK_IN_PROFILE_FAN_SUCCESS:
      state.anotherFanDetail = {
        ...state.anotherFanDetail,
        is_blocked: action.data.data.is_blocked,
      };
      return {
        ...state,
        anotherFanDetail: state.anotherFanDetail,
        loading: false,
        success: true,
      };
    case TYPES.GET_COUNTRY_REQUEST:
      return {
        ...state,
        listCountryLoading: true,
      };
    case TYPES.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case TYPES.SIGN_IN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.AUTHENTICATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.SEND_OTP_REQUEST:
    case TYPES.VERIFY_OTP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.CHECK_TOKEN_REQUEST:
      return {
        ...state,
        loading: true,
        status: null,
      };
    case TYPES.CHECK_TOKEN_SUCCESS:
      return {
        ...state,
        checkToken: action.data.data.message,
        status: true,
        loading: false,
      };
    case TYPES.SIGN_IN_SUCCESS:
      if( action.data.isVerify) {
        Storage.set('USER_REQUEST_TOKEN', action.data.data);
      } else {
        Storage.set('USER_ACCESS_TOKEN', action.data.data);
      }
      return {
        ...state,
        token: action.data.data,
        // loading: false,
        status: true,
      };
    case TYPES.AUTHENTICATION_SUCCESS:
    case TYPES.VERIFY_OTP_SUCCESS:
      Storage.set('USER_ACCESS_TOKEN', action.data.data);
      return {
        ...state,
        token: action.data.data,
        loading: false,
        status: true,
      };
    case TYPES.SEND_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
        status: true,
      };
    case TYPES.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case TYPES.CHECK_TOKEN_FAILED:
      return {
        ...state,
        status: false,
        error: action.data.error,
        loading: false,
        checkToken: action.data.error.message,
      };
    case TYPES.RESET_PASSWORD_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
      };
    case TYPES.SIGN_IN_FAILED:
      return {
        ...state,
        error: action.data.error,
        loading: false,
        status: false,
      };
    case TYPES.AUTHENTICATION_FAILED:
      return {
        ...state,
        error: action.data.error,
        loading: false,
        status: false,
      };
    case TYPES.SEND_OTP_FAILED:
    case TYPES.VERIFY_OTP_FAILED:
    case TYPES.EDIT_EMAIL_FAILED:
    case TYPES.REVIEW_IMAGE_FAILED:
    case TYPES.REQUEST_LEAVE_FAILED:
    case TYPES.GET_STATS_FAILED:
    case TYPES.USER_REPORT_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
      };
    // forgot password
    case TYPES.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        status: false,
        loading: true,
      };
    case TYPES.SIGN_UP_REQUEST:
      return {
        ...state,
        status: false,
        loading: true,
        signUpFailed: false,
      };
    case TYPES.GET_USER_INFO_REQUEST:
      return {
        ...state,
        status: false,
        loading: true,
        creatorUpdateProfileSuccess: false,
      };
    case TYPES.GET_USER_INFO_ME_REQUEST:
      return {
        ...state,
        status: false,
        loading: true,
        creatorUpdateProfileSuccess: false,
      };
    case TYPES.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case TYPES.FORGOT_PASSWORD_FAILED:
    case TYPES.GET_DETAIL_FAN_FAILED:
    case TYPES.FOLLOW_OR_UN_FOLLOW_USER_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        isLoadingFollow: false,
      };
    case TYPES.LOGOUT_FAILED: {
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
        logoutProcess: false,
      };
    }
    case TYPES.BLOCK_OR_UNBLOCK_USER_FAILED:
    case TYPES.BLOCK_OR_UNBLOCK_IN_PROFILE_FAN_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
      };
    case TYPES.SIGN_UP_SUCCESS:
      const { isIdol } = useUserRole();
      return isIdol === true
        ? { ...state, loading: false, status: true }
        : {
            ...state,
            // token: action.data.access_token,
            loading: false,
            status: true,
            signUpFailed: false,
            signUpSuccess: true,
          };
    case TYPES.SIGN_UP_FAILED:
      return {
        ...state,
        status: false,
        error: action.data.error,
        loading: false,
        signUpFailed: true,
        signUpSuccess: false,
      };
    case TYPES.GET_USER_INFO_FAILED:
      return {
        ...state,
        status: false,
        error: action.data.error,
        loading: false,
        creatorUpdateProfileSuccess: false,
        isLoadProfile: false,
      };
    case TYPES.GET_USER_INFO_ME_FAILED:
      return {
        ...state,
        status: false,
        error: action.data.error,
        loading: false,
        creatorUpdateProfileSuccess: false,
        isLoadProfile: false,
      };
    case TYPES.SIGN_OUT:
      Storage.remove(STORAGE.USER_ACCESS_TOKEN);
      Storage.remove(STORAGE.CURRENT_USER_KEY);
      return false;
    case TYPES.GET_USER_INFO_SUCCESS:
      Storage.set(STORAGE.CURRENT_USER_KEY, action.data.data);
      return {
        ...state,
        data: action.data.data.data,
        status: false,
        loading: false,
        creatorUpdateProfileSuccess: false,
        isLoadProfile: true,
      };
    case TYPES.GET_USER_INFO_ME_SUCCESS:
      return {
        ...state,
        dataUser: action.data.data.data,
        status: false,
        loading: false,
        creatorUpdateProfileSuccess: false,
        isLoadProfile: true,
      };
    //change password
    case TYPES.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        status: false,
        loading: true,
      };
    case TYPES.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        status: action.data.data.success,
        loading: false,
      };
    case TYPES.CHANGE_PASSWORD_FAILED:
      return {
        ...state,
        status: action.data.status,
        error: action.data.error,
        loading: false,
      };

    //edit profile
    case TYPES.EDIT_PROFILE_REQUEST:
      return {
        ...state,
        status: false,
        loading: true,
        creatorUpdateProfileFailed: false,
        creatorUpdateProfileSuccess: false,
      };
    case TYPES.EDIT_PROFILE_SUCCESS:
      return {
        ...state,
        status: action.data.data.success,
        loading: false,
        creatorUpdateProfileFailed: false,
        creatorUpdateProfileSuccess: true,
      };
    case TYPES.EDIT_PROFILE_FAILED:
      return {
        ...state,
        status: action.data.status,
        error: action.data.error,
        loading: false,
        creatorUpdateProfileFailed: true,
        creatorUpdateProfileSuccess: false,
      };

    //edit profile step 1
    case TYPES.EDIT_PROFILE_BASIC_REQUEST:
      return {
        ...state,
        status: false,
        loading: true,
        creatorUpdateProfileBasicFailed: false,
        creatorUpdateProfileBasicSuccess: false,
      };
    case TYPES.EDIT_PROFILE_BASIC_SUCCESS:
      return {
        ...state,
        status: action.data.data.success,
        loading: false,
        creatorUpdateProfileBasicFailed: false,
        creatorUpdateProfileBasicSuccess: true,
      };
    case TYPES.EDIT_PROFILE_BASIC_FAILED:
      return {
        ...state,
        status: action.data.status,
        error: action.data.error,
        loading: false,
        creatorUpdateProfileBasicFailed: true,
        creatorUpdateProfileBasicSuccess: false,
      };

    //get category
    case TYPES.CATEGORY_PROFILE_REQUEST:
      return {
        ...state,
        loading: false,
      };
    case TYPES.CATEGORY_PROFILE_SUCCESS:
      return {
        ...state,
        category: action.data.data.data,
        loading: false,
      };
    case TYPES.CATEGORY_PROFILE_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
      };

    // creator get list follow
    case TYPES.CREATOR_LIST_SUBSCRIBER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.CREATOR_LIST_SUBSCRIBER_SUCCESS:
      return {
        ...state,
        pagination: action?.data?.data?.pagination,
        paginationSubcriber: action?.data?.data?.pagination,
        initPageSub: action?.data?.data?.pagination?.current_page,
        listSubscriber:
          action?.data?.data?.pagination?.current_page === 1
            ? action?.data?.data?.data
            : [...state?.listSubscriber, ...action?.data?.data?.data],
        loading: false,
      };
    case TYPES.CREATOR_LIST_SUBSCRIBER_FAILED:
      return {
        ...state,
        status: false,
        error: action.data.error,
        loading: false,
      };
    // creator get list fan
    case TYPES.CREATOR_LIST_FAN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.CREATOR_LIST_FAN_SUCCESS:
      return {
        ...state,
        paginationFollower: action?.data?.data?.pagination,
        listFans:
          action?.data?.data?.pagination?.current_page === 1
            ? action?.data?.data?.data
            : [...state?.listFans, ...action?.data?.data?.data],
        loading: false,
      };
    case TYPES.CREATOR_LIST_FAN_FAILED:
      return {
        ...state,
        status: false,
        error: action.data.error,
        loading: false,
      };
    // creator get list block
    case TYPES.CREATOR_LIST_BLOCK_REQUEST:
      const listBlockLength = state.listBlock.length;
      if (listBlockLength) {
        return {
          ...state,
          loadingListBlockMore: true,
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    case TYPES.CREATOR_LIST_BLOCK_SUCCESS:
      return {
        ...state,
        listBlock:
          action.data.listBlockPagination.current_page === 1
            ? [...action.data.data]
            : [...state.listBlock, ...action.data.data],
        listBlockPagination: { ...action.data.listBlockPagination },
        initPageBlock: action.data.listBlockPagination.current_page,
        loading: false,
        loadingListBlockMore: false,
      };
    case TYPES.CREATOR_LIST_BLOCK_FAILED:
      return {
        ...state,
        status: false,
        error: action.data.error,
        loading: false,
        loadingListBlockMore: false,
      };

    // creator get list buyer
    case TYPES.CREATOR_LIST_BUYER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.CREATOR_LIST_BUYER_SUCCESS:
      return {
        ...state,
        listBuyerPagination: { ...action?.data?.data?.pagination },
        initPageBuyed: action?.data?.data?.pagination?.current_page,
        listBuyer:
          action?.data?.data?.pagination?.current_page === 1
            ? action?.data?.data?.data
            : [...state?.listBuyer, ...action?.data?.data?.data],
        loading: false,
      };
    case 'CREATOR_LIST_BUYER_REFRESH':
      return {
        ...state,
        listBuyer: [],
        loading: false,
      };
    case TYPES.CREATOR_LIST_BUYER_FAILED:
      return {
        ...state,
        status: false,
        error: action.data.error,
        loading: false,
      };

    // creator get list content
    case TYPES.CREATOR_LIST_CONTENT_REQUEST:
      return {
        ...state,
        loadingListContent: true,
      };
    case TYPES.CREATOR_LIST_CONTENT_SUCCESS:
      return {
        ...state,
        listContent: action.data.data,
        loadingListContent: false,
      };
    case TYPES.CREATOR_LIST_CONTENT_FAILED:
      return {
        ...state,
        status: false,
        error: action.data.error,
        loadingListContent: false,
      };

    // creator get list buyer
    case TYPES.CREATOR_LIST_RANKING_BUYER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.CREATOR_LIST_RANKING_BUYER_SUCCESS:
      return {
        ...state,
        listRankingBuyer:
          action?.data?.data?.pagination?.current_page === 1
            ? action?.data?.data?.data
            : [...state?.listRankingBuyer, ...action?.data?.data?.data],
        pagination: action?.data?.data?.pagination,
        paginationRanking: action?.data?.data?.pagination?.current_page,
        loading: false,
      };
    case 'CREATOR_LIST_RANKING_BUYER_REFRESH':
      return {
        ...state,
        listRankingBuyer: [],
        loading: false,
      };
    case TYPES.CREATOR_LIST_RANKING_BUYER_FAILED:
      return {
        ...state,
        status: false,
        error: action.data.error,
        loading: false,
      };

    //verify email
    case TYPES.VERIFY_EMAIL_REQUEST:
      return {
        ...state,
        status: false,
        loading: true,
      };
    case TYPES.VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        status: true,
        loading: false,
        tokenExist: true,
        urlExpired: false,
      };
    case TYPES.VERIFY_EMAIL_FAILED:
      return {
        ...state,
        status: true,
        error: action.data.error,
        loading: false,
        tokenExist: false,
      };
    case TYPES.VERIFY_EMAIL_URL_FAILED:
      return {
        ...state,
        status: true,
        error: action.data.error,
        loading: false,
        tokenExist: false,
        urlExpired: true,
      };

    // list user following
    case TYPES.USER_FOLLOWING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.USER_FOLLOWING_SUCCESS:
      const addIsFollowed = action?.data?.data?.data.map((item) => ({
        ...item,
        is_followed: 1,
      }));
      return {
        ...state,
        loading: false,
        paginationFollowing: action?.data?.data?.pagination,
        userListFollowing:
          action?.data?.data?.pagination?.current_page === 1
            ? action?.data?.data?.data
            : [...state?.userListFollowing, ...action?.data?.data?.data],
      };
    case TYPES.USER_FOLLOWING_FAILED:
      return {
        ...state,
        error: action.data.error,
        loading: false,
      };

    //creator list package
    case TYPES.CREATOR_LIST_PACKAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        creatorListPackage: [...state?.creatorListPackage, ...action?.data?.data?.data],
      };
    // get creater package detail
    case TYPES.GET_CREATOR_PACKAGE_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        creatorPackageDetail: action.data.data,
      };

    // fan get list bookmark
    case TYPES.FAN_GET_LIST_BOOKMARK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.FAN_GET_LIST_BOOKMARK_SUCCESS:
      return {
        ...state,
        loading: false,
        fanListBookmark:
          action?.data?.pagination?.current_page === 1
            ? action?.data?.data
            : [...state?.fanListBookmark, ...action?.data?.data],
        fanListBookmarkPagination: action?.data?.pagination,
        initPageBook: action?.data?.pagination?.current_page,
      };
    case TYPES.FAN_GET_LIST_BOOKMARK_FAILED:
      return {
        ...state,
        error: action.data.error,
        loading: false,
      };

    // LIKE LIST BOOKMARK
    case TYPES.LIKE_REQUEST:
      return {
        ...state,
      };
    case TYPES.LIKE_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
      };
    case TYPES.LIKE_BOOKMARK_SUCCESS:
      const bookmarkLiked = action.data.is_liked ? 0 : 1;
      state.fanListBookmark = state.fanListBookmark.map((item) => {
        if (+item.id === +action.data.id) {
          return {
            ...item,
            is_liked: bookmarkLiked,
            number_like: bookmarkLiked ? item.number_like + 1 : item.number_like - 1,
          };
        }
        return item;
      });
      return {
        ...state,
        fanListBookmark: state.fanListBookmark,
      };
    case TYPES.BOOK_MARK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.SET_COUNT_LIKE_POST_BOOKMARK_SUCCESS:
      state.fanListBookmark = state?.fanListBookmark?.map((item) => {
        if (+item?.id === +action.data?.data?.id) {
          return {
            ...item,
            is_liked: +action.data?.data?.is_liked,
            number_like: item?.number_like + Number(action.data?.data?.number_like),
          };
        }
        return item;
      });
      return {
        ...state,
        loadingFollow: false,
        fanListBookmark: state?.fanListBookmark,
      };
    case TYPES.SET_COUNT_COMMENT_BOOKMARK_SUCCESS:
      state.fanListBookmark = state?.fanListBookmark?.map((item) => {
        if (+item?.id === +action.data?.data?.id) {
          return {
            ...item,
            number_comment: +action.data?.data?.number_comment,
          };
        }
        return item;
      });
      return {
        ...state,
        loadingFollow: false,
        fanListBookmark: state?.fanListBookmark,
      };
    case TYPES.BOOK_MARK_SUCCESS:
      state.fanListBookmark = state.fanListBookmark.map((item) => {
        if (+item.id === +action.data.data.post_id) {
          const newItem = { ...item };
          if (!action.data.data.user_bookmark_status) {
            newItem.user_bookmark_status = 0;
            --newItem.number_bookmark;
          } else {
            newItem.user_bookmark_status = 1;
            ++newItem.number_bookmark;
          }
          return newItem;
        } else return item;
      });
      state.fanListBookmark = state.fanListBookmark.filter(
        (item) => item.user_bookmark_status !== 0
      );
      state.listFanViewed = state.listFanViewed.map((item) => {
        if (+item.id === +action.data.data.post_id) {
          const newItem = { ...item };
          if (newItem.user_bookmark_status === 1) {
            newItem.user_bookmark_status = 0;
            --newItem.number_bookmark;
          } else {
            newItem.user_bookmark_status = 1;
            ++newItem.number_bookmark;
          }
          return newItem;
        } else return item;
      });
      state.listFanViewedPosts = state.listFanViewedPosts.map((item) => {
        if (+item.id === +action.data.data.post_id) {
          const newItem = { ...item };
          if (newItem.user_bookmark_status === 1) {
            newItem.user_bookmark_status = 0;
            --newItem.number_bookmark;
          } else {
            newItem.user_bookmark_status = 1;
            ++newItem.number_bookmark;
          }
          return newItem;
        } else return item;
      });
      return {
        ...state,
        fanListBookmark: state.fanListBookmark,
        listFanViewedPosts: state.listFanViewedPosts,
        listFanViewed: state.listFanViewed,
        loading: false,
      };
    case TYPES.BOOK_MARK_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
      };

    case TYPES.FAN_DONATE_CREATOR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.FAN_DONATE_CREATOR_SUCCESS:
      return {
        ...state,
        fanDonateCoin: action.data.data.data,
        // loading: false,
      };
    case TYPES.FAN_DONATE_CREATOR_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        // loading: false,
      };

    case TYPES.USER_CHANGE_EMAIL_1_REQUEST:
      return {
        ...state,
        loading: true,
        changeEmailStep1: false,
      };
    case TYPES.USER_CHANGE_EMAIL_1_SUCCESS:
      return {
        ...state,
        loading: false,
        changeEmailStep1: true,
      };
    case TYPES.USER_CHANGE_EMAIL_1_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
        changeEmailStep1: false,
      };

    case TYPES.USER_CHANGE_EMAIL_CONFIRM_1_REQUEST:
      return {
        ...state,
        loading: true,
        changeEmailConfim1Failed: null,
      };
    case TYPES.USER_CHANGE_EMAIL_CONFIRM_1_SUCCESS:
      return {
        ...state,
        loading: false,
        changeEmailConfim1Failed: false,
      };
    case TYPES.USER_CHANGE_EMAIL_CONFIRM_1_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
        changeEmailConfim1Failed: true,
      };

    case TYPES.USER_CHANGE_EMAIL_2_REQUEST:
      return {
        ...state,
        loading: true,
        changeEmailStep2: null,
      };
    case TYPES.USER_CHANGE_EMAIL_2_SUCCESS:
      return {
        ...state,
        loading: false,
        changeEmailStep2: true,
      };
    case TYPES.USER_CHANGE_EMAIL_2_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
        changeEmailStep2: false,
      };

    case TYPES.USER_CHANGE_EMAIL_CONFIRM_2_REQUEST:
      return {
        ...state,
        loading: true,
        changeEmailConfirmStep2: null,
        changeEmailConfim2Failed: null,
      };
    case TYPES.USER_CHANGE_EMAIL_CONFIRM_2_SUCCESS:
      return {
        ...state,
        loading: false,
        changeEmailConfirmStep2: true,
        changeEmailConfim2Failed: false,
      };
    case TYPES.USER_CHANGE_EMAIL_CONFIRM_2_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
        changeEmailConfirmStep2: false,
        changeEmailConfim2Failed: true,
      };
    case TYPES.LOGOUT_WITH_NEW_TOKEN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.LOGOUT_WITH_NEW_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case TYPES.LOGOUT_WITH_NEW_TOKEN_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
      };

    case TYPES.FAN_VIEWED_POSTS_REQUEST:
      return {
        ...state,
        loading: true,
        listFanViewed: [],
      };
    case TYPES.FAN_VIEWED_POSTS_SUCCESS:
      return {
        ...state,
        listFanViewedPosts: [...state.listFanViewedPosts, ...action.data.data],
        loading: false,
      };
    case TYPES.FAN_VIEWED_POSTS_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
      };
    case TYPES.FAN_VIEWED_REQUEST:
      return {
        ...state,
        loading: true,
        listFanViewedPosts: [],
      };
    case TYPES.FAN_VIEWED_SUCCESS:
      return {
        ...state,
        listFanViewed: [...state.listFanViewed, ...action.data.data],
        loading: false,
      };
    case TYPES.FAN_VIEWED_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
      };
    case TYPES.CREATOR_ID_POSTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.CREATOR_ID_POSTS_SUCCESS:
      return {
        ...state,
        creatorIdPosts: action.data.data,
        loading: false,
      };
    case TYPES.CREATOR_ID_POSTS_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
      };

    case TYPES.REQUEST_APPROVE_REQUEST:
      return {
        ...state,
        loading: true,
        requestApproveSuccess: false,
      };
    case TYPES.REQUEST_APPROVE_SUCCESS:
      return {
        ...state,
        loading: false,
        requestApproveSuccess: true,
      };
    case TYPES.REQUEST_APPROVE_FAILED:
      return {
        ...state,
        loading: false,
        requestApproveSuccess: false,
      };

    case TYPES.GET_PROFILE_ANOTHER_FAN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_PROFILE_ANOTHER_FAN_SUCCESS:
      return {
        ...state,
        loading: false,
        anotherFanDetail: action?.data?.data,
      };
    case TYPES.GET_PROFILE_ANOTHER_FAN_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data.error,
      };

    case TYPES.FAN_GET_LIST_PACKAGES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.FAN_GET_LIST_PACKAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        initPageSubscribe: action?.data?.fanListPackagesPagination.current_page,
        fanListPackages:
          action?.data?.fanListPackagesPagination.current_page === 1
            ? [...action?.data?.data]
            : [...state.fanListPackages, ...action?.data?.data],
        fanListPackagesPagination: { ...action?.data?.fanListPackagesPagination },
      };
    case TYPES.FAN_GET_LIST_PACKAGES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data.error,
      };
    case TYPES.FAN_GET_LIKED_POST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.FAN_GET_LIKED_POST_SUCCESS:
      const newArr =
        action?.data?.pagination?.current_page === 1
          ? action?.data?.data
          : [...state.fanListLikedPostNoGroupBy, ...action?.data?.data];
      const fanListLikedPostPagination = action?.data?.pagination;
      const groupByDate = _.chain(newArr)
        .groupBy('like_created_at')
        .map((value, key) => ({ date: key, value }))
        .value();

      return {
        ...state,
        loading: false,
        fanListLikedPost: groupByDate,
        initPageLike: action?.data?.pagination?.current_page,
        fanListLikedPostPagination: fanListLikedPostPagination,
        fanListLikedPostNoGroupBy: [...state.fanListLikedPostNoGroupBy, ...action?.data?.data],
      };
    case TYPES.FAN_GET_LIKED_POST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data.error,
      };

    // creator get list buyer
    case TYPES.FAN_LIST_RANKING_BUYER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.FAN_LIST_RANKING_BUYER_SUCCESS:
      return {
        ...state,
        listRankingBuyer: [...state.listRankingBuyer, ...action.data.data.data],
        pagination: action?.data?.data?.pagination,
        loading: false,
      };
    case 'FAN_LIST_RANKING_BUYER_REFRESH':
      return {
        ...state,
        listRankingBuyer: [],
        loading: false,
      };
    case TYPES.FAN_LIST_RANKING_BUYER_FAILED:
      return {
        ...state,
        status: false,
        error: action.data.error,
        loading: false,
      };

    case TYPES.GET_USER_POINTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_USER_POINTS_SUCCESS:
      return {
        ...state,
        loading: false,
        userPoints: action.data.data.data,
      };
    case TYPES.GET_USER_POINTS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.data.error,
      };

    case TYPES.GET_SALE_CONTENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_SALE_CONTENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        listSaleContents:
          action?.data?.data?.pagination?.current_page === 1
            ? action?.data?.data?.data
            : [...state.listSaleContents, ...action?.data?.data?.data],
        listSaleContentsPagination: action?.data?.data?.pagination,
      };
    case TYPES.GET_SALE_CONTENTS_FAILED:
      return {
        ...state,
        loading: false,
        error: action?.data?.error,
      };

    case TYPES.GET_CONTENT_BUYER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_CONTENT_BUYER_SUCCESS:
      return {
        ...state,
        loading: false,
        initPageBuyer: action?.data?.data?.pagination?.current_page,
        listContentBuyers:
          action?.data?.data?.pagination?.current_page === 1
            ? action?.data?.data?.data
            : [...state.listContentBuyers, ...action?.data?.data?.data],
        listContentBuyersPagination: action?.data?.data?.pagination,
      };
    case TYPES.GET_CONTENT_BUYER_FAILED:
      return {
        ...state,
        loading: false,
        error: action?.data?.error,
      };

    case TYPES.SEARCH_SHORT_VIDEO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.SEARCH_SHORT_VIDEO_SUCCESS:
      return {
        ...state,
        loading: false,
        isLoadDataSearchShort: true,
        searchShortVideo:
          action?.data?.pagination?.current_page === 1
            ? action?.data?.data
            : [...state?.searchShortVideo, ...action?.data?.data].reduce(
                (accumulator, current) => {
                  let exists = accumulator.find((item) => {
                    return item.id === current.id;
                  });
                  if (!exists) {
                    accumulator = accumulator.concat(current);
                  }
                  return accumulator;
                },
                []
              ),
        searchShortVideoPagination: action?.data?.pagination,
      };
    case TYPES.SEARCH_SHORT_VIDEO_FAILED:
      return {
        ...state,
        error: action.data.error,
        loading: false,
      };

    case TYPES.SEARCH_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.SEARCH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        initPageSeachUser: action?.data?.pagination?.current_page,
        searchUser:
          action?.data?.pagination?.current_page === 1
            ? action?.data?.data
            : [...state?.searchUser, ...action?.data?.data],
        searchUserPagination: action?.data?.pagination,
      };
    case TYPES.SEARCH_USER_FAILED:
      return {
        ...state,
        error: action.data.error,
        loading: false,
      };

    case TYPES.SEARCH_HASHTAG_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.SEARCH_HASHTAG_SUCCESS:
      return {
        ...state,
        loading: false,
        searchHashtag:
          action?.data?.pagination?.current_page === 1
            ? action?.data?.data
            : [...state?.searchHashtag, ...action?.data?.data],
        searchHashtagPagination: action?.data?.pagination,
      };
    case TYPES.SEARCH_HASHTAG_FAILED:
      return {
        ...state,
        error: action.data.error,
        loading: false,
      };

    case TYPES.SEARCH_HASHTAG_POSTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.SEARCH_HASHTAG_POSTS_SUCCESS:
      return {
        ...state,
        loading: false,
        searchHashtagPosts:
          action?.data?.pagination?.current_page === 1
            ? action?.data?.data
            : [...state?.searchHashtagPosts, ...action?.data?.data],
        searchHashtagPostsPagination: action?.data?.pagination,
      };
    case TYPES.SEARCH_HASHTAG_POSTS_FAILED:
      return {
        ...state,
        error: action.data.error,
        loading: false,
      };

    case TYPES.GET_COMMENT_DETAILS_SUCCESS:
      const commentDetail =
        action?.data?.data?.pagination?.current_page === 1
          ? action?.data?.data?.data
          : [...state?.commentDetail, ...action?.data?.data?.data];

      return {
        ...state,
        paginationComment: action?.data?.data?.pagination,
        loadingCommentDetail: false,
        commentDetail: commentDetail,
      };

    case TYPES.POST_NORMAL_BUY_PACKAGES_SUCCESS:
      state.fanListBookmark = state.fanListBookmark.map((item) => {
        if (+item?.packages[0]?.id === +action.data.res.payload) {
          const newItem = { ...item };
          if (newItem.is_media_display === 1) {
            newItem.is_media_display = 0;
          } else {
            newItem.is_media_display = 1;
          }
          return newItem;
        } else return item;
      });

      return {
        ...state,
        fanListBookmark: state.fanListBookmark,
      };

    case TYPES.LIKE_POST_DETAILS_SUCCESS:
      state.searchShortVideo = state.searchShortVideo.map((item) => {
        if (+item.id === +action?.data?.data?.post_id) {
          const newItem = { ...item };
          if (newItem.is_liked === 1) {
            newItem.is_liked = 0;
            --newItem.number_like;
          } else {
            newItem.is_liked = 1;
            ++newItem.number_like;
          }

          return newItem;
        } else return item;
      });

      return {
        ...state,
        searchShortVideo: state.searchShortVideo,
        isLoadDataSearchShort: false,
      };

    case 'SET_ID_SCROLL_BACK_FOLLOWING':
      return { ...state, idScrollBackFollowing: action?.data?.id };
    case 'SET_ID_SCROLL_BACK_FOLLOWER':
      return { ...state, idScrollBackFollower: action?.data?.id };
    case 'CLEAR_SCROLL_BACK_FOLLOW':
      return {
        ...state,
        idScrollBackFollower: null,
        idScrollBackFollowing: null,
        listFans: [],
        userListFollowing: [],
        paginationFollower: {},
        paginationFollowing: {},
      };
    case 'CLEAR_SCROLL_BACK_FOLLOWING':
      return {
        ...state,
        idScrollBackFollowing: null,
        userListFollowing: [],
        paginationFollowing: {},
      };
    case 'CLEAR_SCROLL_BACK_FOLLOWER':
      return {
        ...state,
        idScrollBackFollower: null,
        listFans: [],
        paginationFollower: {},
      };

    case 'RESET_ACTION_GET_PROFILE_ANOTHER_FAN':
      return { ...state, anotherFanDetail: null };

    case 'HANDLE_RESET_FAN_VIEWED':
      return { ...state, listFanViewed: [] };

    case 'HANDLE_RESET_FAN_VIEWED_POST':
      return { ...state, listFanViewedPosts: [] };

    case 'RESET_CONTENT_BUYER':
      return { ...state, listContentBuyers: [] };

    case 'LOADING_RECOMMENDED_DATA':
      return {
        ...state,
        loadingRecommended: true,
        recommendedData: action?.data?.data,
      };

    case 'LOADING_LIST_POST_CONTENT_RECOMMEND':
      return {
        ...state,
        loadingListPostRecommended: true,
        listPostrecommendedData: action?.data?.data,
      };
    case 'CLEAR_IS_LOAD_DATA_SEARCH_SHORT':
      return {
        ...state,
        isLoadDataSearchShort: true,
      };

    case 'RESET_DATA_PROFILE':
      return {
        ...state,
        data: null,
      };

    case 'RESET_DATA_PROFILE_USER':
      return {
        ...state,
        dataUser: {},
      };
    case 'ID_POST_SEARCH_USER':
      return {
        ...state,
        idSearchUser: action.data,
      };
    case 'RESET_ID_SEARCH_USER':
      return {
        ...state,
        initPageSeachUser: 0,
        idSearchUser: {},
        searchUser: [],
      };
    case 'ID_POST_BUYER':
      return {
        ...state,
        idBuyer: action.data,
      };
    case 'RESET_ID_BUYER':
      return {
        ...state,
        initPageBuyer: 0,
        idBuyer: {},
        listContentBuyers: [],
      };
    case 'RESET_ID_BUYER_NEW':
      return {
        ...state,
        initPageBuyer: 0,
        idBuyer: {},
      };
    case 'ID_POST_BUYED':
      return {
        ...state,
        idBuyed: action.data,
      };
    case 'RESET_ID_BUYED':
      return {
        ...state,
        initPageBuyed: 0,
        idBuyed: {},
        listBuyer: [],
      };
    case 'ID_SUBCRIBE':
      return {
        ...state,
        idSubscribe: action.data,
      };
    case 'RESET_SUBCRIBE':
      return {
        ...state,
        initPageSubscribe: 0,
        idSubscribe: {},
        fanListPackages: [],
      };
    case 'ID_LIKE':
      return {
        ...state,
        idLike: action.data,
      };
    case 'UPDATE_LIST_LIKE_FROM_DETAIL_POST':
      if (state.fanListLikedPost?.length > 0) {
        if (action.data?.value) {
          const valueListLike = state.fanListLikedPost;
          for (let i = 0; i < valueListLike?.length; i++) {
            const listLike = valueListLike[i]?.value;
            if (valueListLike[i]?.date === action.data?.value?.like_created_at) {
              listLike?.unshift(action.data?.value);
              break;
            } else {
              valueListLike?.unshift({
                date: action.data?.value?.like_created_at,
                value: [action.data?.value],
              });
              break;
            }
          }
        } else {
          state.fanListLikedPost = state.fanListLikedPost
            ?.map((item) => {
              const listLike = item?.value;
              const newValue = listLike?.filter((itemLike) => {
                return itemLike?.post_id !== action.data?.id;
              });
              if (newValue?.length > 0) {
                return {
                  ...item,
                  value: newValue,
                };
              } else {
                return null;
              }
            })
            ?.filter((itemFilter) => itemFilter);
        }
      }
      return {
        ...state,
      };
    case 'RESET_ID_LIKE':
      return {
        ...state,
        idLike: {},
        fanListLikedPost: [],
        initPageLike: 0,
      };
    case 'ID_FAVORITE':
      return {
        ...state,
        idFavorite: action.data,
      };
    case 'RESET_ID_FAVORITE':
      return {
        ...state,
        idFavorite: {},
        fanListBookmark: [],
        initPageBook: 0,
      };
    case 'SET_ID_SCROLL_BACK_SUBSCRIBER':
      return {
        ...state,
        idScrollBackSubcriber: action.data,
      };
    case 'RESET_ID_SUBSCRIBER':
      return {
        ...state,
        listSubscriber: [],
        idScrollBackSubcriber: {},
        initPageSub: 0,
      };
    case 'SET_ID_BLOCK':
      return {
        ...state,
        idBlock: action.data,
      };
    case 'RESET_ID_BLOCK':
      return {
        ...state,
        listBlock: [],
        idBlock: null,
        initPageBlock: 0,
      };
    case 'SET_ID_RANKING_BUY':
      return {
        ...state,
        idRankingBuy: action.data,
      };
    case 'RESET_ID_RANKING_BUY':
      return {
        ...state,
        listRankingBuyer: [],
        idRankingBuy: null,
        paginationRanking: 0,
      };
    case 'UPDATE_LIST_BY_SELECT_SORT':
      if (action.data === 'desc') {
        state.listRankingBuyer = state.listRankingBuyer.map((item, index) => {
          return { ...item, rank: index + 1 };
        });
      } else {
        state.listRankingBuyer = state.listRankingBuyer.map((item, index) => {
          return { ...item, rank: state.listRankingBuyer.length - index };
        });
      }
      return {
        ...state,
        listRankingBuyer: state.listRankingBuyer,
      };
    case 'RESET_ACTION_USER':
      return {
        ...state,
        listFollow: [],
        //listSubscriber: [],
        // listFans: [],
        //listBlock: [],
        //listBuyer: [],
        //listRankingBuyer: [],
        listContent: [],
        // userListFollowing: [],
        creatorListPackage: [],
        //fanListBookmark: [],
        fanMessageDetailCreator: [],
        listFanViewedPosts: [],
        listFanViewed: [],
        creatorUpdateProfileFailed: false,
        creatorUpdateProfileSuccess: false,
        creatorUpdateProfileBasicFailed: false,
        creatorUpdateProfileBasicSuccess: false,
        requestApproveSuccess: false,
        creatorIdPosts: [],
        changeEmailStep1: false,
        changeEmailStep2: false,
        changeEmailConfim2Failed: null,
        //pagination: {},
        //fanListPackages: [],
        //fanListLikedPost: [],
        fanListLikedPostNoGroupBy: [],
        success: false,
      };
    default:
      return state;
  }
};
