// eslint-disable-next-line import/no-anonymous-default-export
export default [
  'LIST_ERROR',
  'SIGN_IN',
  'SIGN_OUT',
  'SET_LOADING',
  'SEARCH',
  'SIGN_UP',
  'LIST_MY_PAGE',
  'FORGOT_PASSWORD',
  'RESET_PASSWORD',
  'CHECK_TOKEN',
  'LIKE',
  'BOOK_MARK',
  'POST_COMMENT',
  'GET_USER_INFO',
  'DELETE_COMMENT',
  'LIKE_COMMENT',
  'EDIT_COMMENT',
  'GET_SURVEY',
  'GET_LIST_SURVEY',
  'DELETE_SURVEY',
  'REP_SURVEY_ANSWER',
  'CREATE_SURVEY',
  'EDIT_PROFILE',
  'EDIT_PROFILE_BASIC',
  'SEARCH_USER',
  'CHANGE_PASSWORD',
  'GET_CATEGORY',
  'SEARCH_HASHTAG',
  'LIST_ALL_PACKAGE',
  'LIST_ALL_USER_BY_KEY',
  'LIST_ALL_USER_BY_KEY_DISTRIBUTION',
  'LIST_ALL_FAN',
  'CREATOR_CREATE_LIVE',
  'CREATOR_UPDATE_LIVE', // 14-5-2022
  'CREATOR_REVIEW_LIVE',
  'CREATOR_CHANGE_ACTIVE_DRAF_LIVE',
  'CREATOR_CHANGE_STATUS_TO_LIVE',
  'CREATOR_CHANGE_STATUS_TO_PAUSE',
  'CREATOR_CHANGE_STATUS_TO_END',

  'LIST_LIVES', //* SU DUNG O MAN F014_1
  'IS_FOLLOW',
  'CREATOR_GET_POST_DETAIL',
  'GET_LIST_COMMENT',
  'CREATOR_GET_LIST_COMMENT',
  'CATEGORY_PROFILE',
  'CREATOR_LIST_SUBSCRIBER',
  'CREATOR_LIST_FAN',
  'CREATOR_LIST_BLOCK',
  'POST_SURVEY',
  'GET_COUNTRY',
  'MORE_TIME_LIVESTREAM',
  'CREATOR_GET_LIVE_BY_KEY',
  'LIST_NOTIFICATION',
  'GET_DETAIL_NOTICATION',
  'SEEN_NOTIFICATION',
  'EDIT_EMAIL',
  'REVIEW_IMAGE',
  'REQUEST_LEAVE',
  'GET_SETTING_NOTIFICATION',
  'SETTING_NOTIFICATION',
  'CREATOR_UPDATE_LIVESTREAM',
  'GET_STATS',
  'GET_LIST_LIVE_STREAM',
  'GET_POST_DETAIL',
  'CREATOR_POST_ARTICLE',
  'CREATOR_EDIT_ARTICLE',
  'CREATOR_GET_DETAIL_PREMIUM_TICKET',
  'CREATOR_POST_PREMIUM_TICKET',
  'CREATOR_EDIT_PREMIUM_TICKET',
  'GET_LIST_POST',
  'GET_SEARCH_POST',
  'GET_DETAIL_FAN',
  'FOLLOW_FAN',
  'BLOCK_OR_UNBLOCK_USER',
  'CREATOR_LIST_BUYER',
  'CREATOR_LIST_RANKING_BUYER',
  'CREATOR_LIST_CONTENT',
  'USER_REPORT',
  'LOGOUT',
  'CREATOR_PACKAGE',
  'UPDATE_CREATOR_PACKAGE',
  'DELETE_CREATOR_PACKAGE',
  'GET_CREATOR_PACKAGE_DETAIL',

  'VERIFY_EMAIL',
  'VERIFY_EMAIL_URL',

  'DELETE_LIVE_STREAM',

  'USER_FOLLOWING',
  'GET_LIST_CREATOR',

  'CREATOR_LIST_PACKAGE',
  'GET_DETAIL_LIVE_STREAM',
  'CREATOR_DELETE_ARTICLE',
  'CREATOR_DELETE_POST',

  //C085
  'GET_LIST_POST_TIMELINE',
  'GET_LIST_POST_TIMELINE_LANDING',

  //COMMENT POST DETAIL
  'GET_COMMENT_DETAIL',
  'GET_REPLY_COMMENT_DETAIL',
  'POST_COMMENT_DETAIL',
  'POST_COMMENT_DETAILS',
  'DELETE_REPLY_COMMENT_DETAILS',
  'POST_REPLY_COMMENT_DETAIL',
  'COLLAPSE_REPLY_COMMENT_DETAIL',
  'DELETE_COMMENT_DETAIL',
  'DELETE_REPLY_COMMENT_DETAIL',
  'EDIT_COMMENT_DETAIL',
  'EDIT_REPLY_COMMENT_DETAIL',
  'LIKE_COMMENT_DETAIL',
  'BEFORE_LIKE_COMMENT_DETAIL',
  'LIKE_REPLY_COMMENT_DETAIL',
  'LIKE_REPLY_COMMENT_NOW',
  'LIKE_POST_DETAIL',
  'LIKE_BOOKMARK',
  'UPDATE_LIKE_POST_DETAILS',
  'BOOK_MARK_DETAIL',
  'FOLLOW_USER_COMMENT_DETAIL',
  'FOLLOW_USER_REPLY_COMMENT_DETAIL',
  'SEARCH_POST',

  'FAN_GET_LIST_BOOKMARK',

  //donate
  'FAN_DONATE_CREATOR',

  //C091
  'RESET_LIST_ALL_LIVE_CREATOR',
  // C020
  'DETAIL_CREATOR',
  'POSTS_CREATOR',
  'LIVE_STREAM_CREATOR',
  'LIST_POSTS_CREATOR',
  'LIST_POST_CREATOR',
  'POST_LIKE_CREATOR',
  'GET_LIST_NOTIFICATIONS_CREATOR',
  'LIST_POSTS_SHORT_VIDEO',

  // C158
  'LIST_RECORDING',
  'GET_DETAIL_RECORDING',
  'DELETE_RECORDING',
  'UPDATE_DETAIL_RECORDING',

  //F002
  'SEARCH_LIST_CREATOR',
  'SEARCH_LIST_CREATOR_BY_TAG',

  //F004
  'USER_CHANGE_EMAIL_1',
  'USER_CHANGE_EMAIL_2',
  'USER_CHANGE_EMAIL_CONFIRM_1',
  'USER_CHANGE_EMAIL_CONFIRM_2',
  'LOGOUT_WITH_NEW_TOKEN',

  // C092 - TOP DONATE
  'GET_TOP_DONATE',
  //
  'BUY_POST_CONTENT',

  //F016
  'FAN_VIEWED_POSTS',
  'FAN_VIEWED',

  //F017
  'FAN_FOLLOW_CREATOR',
  'GET_LIST_PACKAGE_ANOTHER_CREATOR',
  'POST_UNSUBSCRITION_PACKAGE',
  'POST_BOOKMARK_F017',
  'FAN_LIST_RANKING_BUYER',

  //C084
  'CREATOR_GET_LIST_DATE_LIVE_MONTH',
  'CREATOR_GET_LIST_IN_DATE',

  //F001 CALENDAR
  'FAN_GET_LIST_DATE_LIVE_MONTH',
  'FAN_GET_LIST_IN_DATE',

  //C045
  'CREATOR_ID_POSTS',
  'REQUEST_APPROVE',

  //F022
  'GET_LIST_RANKING_FAVORITE_CREATOR',
  'GET_LIST_RANKING_CONTENT',
  'GET_LIST_RANKING_NEW_CREATOR',
  'GET_LIST_CREATOR_LIVESTREAM',
  'GET_LIST_PICK_UP_CREATOR',
  'GET_LIST_RANKING_CREATOR',
  'GET_LIST_BANNER',
  'GET_SUGGEST_CREATOR',

  //C101
  'GET_LIST_MESSAGE_REQUEST',
  'GET_LIST_MESSAGE_SUCCESS',
  'GET_LIST_MESSAGE_FAILED',
  'GET_LIST_MESSAGE_TO_ADMIN_REQUEST',
  'GET_LIST_MESSAGE_TO_ADMIN_SUCCESS',
  'GET_LIST_MESSAGE_TO_ADMIN_FAILED',
  'DELETE_MESSAGE',
  'DELETE_GROUP_MESSAGE_REQUEST',
  'DELETE_GROUP_MESSAGE_SUCCESS',
  'DELETE_GROUP_MESSAGE_FAILED',

  //C131
  'GET_PROFILE_ANOTHER_FAN',
  'FOLLOW_OR_UN_FOLLOW_USER',
  'BLOCK_OR_UNBLOCK_IN_PROFILE_FAN',

  //C141
  'GET_LIST_FEEDBACK',
  'GET_LIST_FFEDBACK_CATEGORY',
  'POST_FEEDBACK',

  //F021
  'POST_SELL_ARTICLE',
  'GET_FAN_TICKET_CREATOR_TOTAL',

  //F014
  'FAN_BUY_PACKAGE',
  'POST_BUY_ARTICLE',
  'POST_NORMAL_BUY_PACKAGE',
  'FAN_LIST_PACKAGES',

  // AF005
  'GET_LIST_BANNER_AFFILIATOR',
  'GET_LIST_BANNER_DETAIL_AFFILIATOR',
  //F016
  'FAN_GET_LIST_PACKAGES',
  'FAN_GET_LIKED_POST',

  //F014 - C092
  'RESET_DATA_TOP_DONATE',
  //F014
  'FOLLOW_UNFOLLOW_LIVE',
  'FAN_DONATE_CREATOR_LIVE',
  'RESET_STATUS_FAN_DONATE',

  //F015
  'GET_PAYMENT_PACKAGES',
  'POST_PAYMENT_POINT',
  'VERIFY_PAYMENT_VENDO',
  'POST_PAYMENT_POINT_VENDO',
  'APPLY_VOUCHER_PAYMENT',
  'UPDATE_STATUS_VENDO_PAYMENT',
  'SETTING_SYSTEM',
  'GET_FAN_REQUEST_POINT',
  'GET_REQUEST_POINT_VENDO',
  'GET_SETTING_SYSTEM_TYPE_1_PURCHASE',
  "GET_CARDS_CREDIT",
  "GET_PAYMENT_CARDS_CREDIT",
  "CREATE_PAYMENT_CARD_CREDIT",
  "CHANGE_DEFAULT_CARD",
  "SET_ID_CARD_ACTIVE",

  //F000
  'FAN_AFFILIATOR_WITH_BANNER',
  'CHECK_ADMIN_TOKEN',

  //F004-8
  'GET_INFOR_BANK_ACCOUNT',
  'PUT_INFOR_BANK_ACCOUNT',

  //F005
  'GET_LIST_POST_BUYED',
  'GET_LIST_OF_PURCHASED_POST_CREATOR',

  //F019
  'GET_REPORT_CATEGORIES',
  'POST_USER_REPORT',

  //C111
  'GET_TOP_FANS',
  'GET_TRANSACTIONS',
  'GET_TRANSACTIONS_CSV',
  'GET_REVENUE',
  'POST_USER_WITHDRAW_REQUEST',
  'GET_SETTING_LIMIT_PAYMENT',

  //C121
  'GET_CHART_REVENUES',
  'GET_USER_REACTIONS',
  'GET_USER_SUBSCRIPTIONS',
  'GET_CHART_SALES',
  'GET_CHART_LIVESTREAMS',

  //F023
  'GET_SYSTEM_SETTING',

  //F003
  'GET_LIST_POINT_ICON_DONATE',

  //AF004
  'GET_LIST_REVENUE_SALES_HISTORY',
  'GET_LIST_REVENUE_SALES_HISTORY_CSV',
  'GET_LIST_REVENUE_SUMARY',
  'GET_LIST_REVENUE_PAYMENT_HISTORY',
  'GET_LIST_REVENUE_PAYMENT_HISTORY_MONTH',
  'GET_LIMIT_PAYMENT_AFFILICATE',

  // C090
  'GET_LAST_LIVE',
  'CLEAR_DATA_LIVE',
  // C090
  'GET_DETAIL_PRE_LIVESTREAM',
  'CREATE_TOKEN_AGORA',
  'CREATE_TOKEN_AGORA_GUEST',
  'CREATOR_GET_EFFECT',

  // Recommended
  'LOADING_RECOMMENDED_DATA',
  'LOADING_LIST_POST_CONTENT_RECOMMEND',

  // userNew
  'LIST_POSTS_TIME_LINE',
  'LIST_HIGH_LIGHT',
  'DELETE_COMMENT_DETAILS',
  'FOLLOW_USER_REPLY_COMMENT_DETAILS',
  'LIKE_REPLY_COMMENT_DETAILS',
  'LIKE_POST_DETAILS',
  'SEARCH_SHORT_VIDEO',
  'CREATOR_GET_POST_DETAILS',
  'GET_COMMENT_DETAILS',
  'GET_META_COMMENT_OPG',
  'POST_REPLY_COMMENT_DETAILS',
  'RESET_DETAIL_POST',
  'COLLAPSE_REPLY_COMMENT_DETAILS',
  'GET_REPLY_COMMENT_DETAILS',
  'BOOK_MARK_DETAILS',
  'EDIT_REPLY_COMMENT_DETAILS',
  'EDIT_COMMENT_DETAILS',
  'FOLLOW_USER_COMMENT_DETAILS',
  'LIST_3_IMG_MY_PAGE',
  'SEARCH_HASHTAG_POSTS',
  'GET_USER_INFO_ME',
  'GET_USER_INFO_OTHERS',
  'GET_USER_POINTS',
  'GET_SALE_CONTENTS',
  'GET_CONTENT_BUYER',
  'POST_NORMAL_BUY_PACKAGES',
  'BOOK_MARKS',
  'LIST_POSTS_SHORT_VIDEO_CREATOR',
  'UPDATE_DETAIL_RECORDING_V2',
  'CREATOR_DELETE_ARTICLES',
  'POST_RETWEET_REQUEST',
  'GET_DATA_RANKING_PAGE',
  'FOLLOW_USER_RANKING',
  'SET_COUNT_LIKE_POST_RANKING',
  'SET_COUNT_COMMENT_RANKING',
  'SEARCH_SALE_CONTENT',
  'SEARCH_POST_TIMELINE',
  'GET_NOTICATION_MYPAGE',
  'HIDDEN_POST_MYPAGE',
  "GHIM_POST_MYPAGE",
  "GET_DETAIL_POST_META",
  'LIST_BOOKMARK_TIMELINE',
  'SET_COUNT_LIKE_POST_BOOKMARK',
  'SET_COUNT_COMMENT_BOOKMARK',
  'GET_LIST_COMMENT_HIDE_FROM_POST',
  'GET_SUB_LIST_COMMENT_HIDE_FROM_POST',
  "UN_HIDE_COMMENT_FORM_POST",
  'FOLLOW_USER_HIDE_COMMENT',
  'UPDATE_LIKE_COMMENT_HIDE_COMMENT',
  'UPDATE_COMMENT_POST',
  'CREATE_COMMENT_POST',
  'INPUT_VERIFY_PHONE',
  'UPDATE_INPUT_VERIFY_PHONE',
  'CHECK_POINT',
  "AUTHENTICATION",
  'SEND_OTP',
  'VERIFY_OTP'
];
