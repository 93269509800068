import {
  AccountSidebar,
  HeaderListIdolV2,
  ImgCustom,
  LayoutCreatorRight,
  MenuSidebarCustom,
  PopupConfirmFan,
  PopupLoading,
  ToggleButton,
} from 'components';
import { newPathUser } from 'constants/layout/constant';
import useRedirect from 'hooks/useRedirect';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  userGetSettingNotification,
  userPutSettingNotification,
} from 'store/actions/notification';
import './styles.scss';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { resetDataInfoOther, resetIDHomePage } from 'store/actions/usersNew';
import ButtonSubmit from 'components/Button/ButtonSubmit';
import { InputWrapper } from 'components/FormLogin';
import { useForm } from 'react-hook-form';
import { ENV } from 'utils';
import { pushNotify } from 'components/Notification';
import Storage from 'utils/storage';

export default function Index() {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.notification);
  const { t } = useTranslation();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLoadingFollow, setIsLoadingFollow] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });
  const watchCode = watch('code', '');

  const onSubmit = async (data) => {
    setIsLoadingFollow(true);
    try {
      await fetch(`${ENV}/verify-2fa?verify_type=3&code=${data?.code}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
        },
      })
        .then((rs) => rs.json())
        .then((data) => {
          setIsLoadingFollow(false);
          if (data?.success === true) {
            localStorage.setItem('USER_REQUEST_TOKEN_PASS', data?.data?.request_token)
            navigate(`${newPathUser}change-password?request_token=${data?.data?.request_token}`);
          } else {
            pushNotify('error', t('Creator.cardManagement.sercuFail'));
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  //* Error
  const error = useSelector((state) => state.notification.error);
  useRedirect(error);

  return (
    <>
      {isLoadingFollow && <PopupLoading className={'popup-loading'} />}
      {isOpenModal && (
        <PopupConfirmFan text={t('Popup.configSettingSuccess')} className='popup-setting-noti'>
          <div
            className='ok btn'
            onClick={() => {
              setIsOpenModal(false);
              dispatch(resetIDHomePage());
              dispatch(resetDataInfoOther());
              navigate(`${newPathUser}`);
            }}>
            {t('Common.Ok')}
          </div>
        </PopupConfirmFan>
      )}
      <LayoutCreatorRight
        noButtonBack
        titlePage={t('Notification.setting.authen2')}
        loading={loading}
        className='setting-noti-layout'>
        <div className='notification-page-opt'>
          {/* {loading && !setting && (
            <div className='shimmer-loading'>
              <ShimmerPostDetails variant='SIMPLE' />
            </div>
          )} */}
          <div className='setting-noti-secur-opt' style={{}}>
            <div className='text-des-2 d-flex justify-content-center flex-column align-items-center'>
              <div>{t('Notification.setting.title7')}</div>
            </div>
            <div className='block-code-opt-msm'>
              <InputWrapper
                id='fsite-account_number'
                label={t('Notification.setting.titleCode')}
                required
                error={errors?.code ? errors?.code?.message : ''}>
                <input
                  type='text'
                  className='fsite-input'
                  placeholder={t('Notification.setting.placeholderCodeOpt')}
                  name='code'
                  maxLength={6}
                  {...register('code', {
                    required: 'ValidateMsg.codeNumberRequired',
                    pattern: {
                      value: /^[0-9]+$/,
                      message: 'ProfileSetting.correctCodeNumber',
                    },
                  })}
                />
              </InputWrapper>
            </div>
            <div style={{ marginTop: '32px' }}>
              <InputWrapper>
                <div className='row wrapper__btn-comfirm'>
                  <div className='col-12 col-sm-6 col-md-6 mb-3 btn_keep'>
                    <ButtonSubmit
                      className={`btn bg-red w-100 font-weight-700 text-white app-btn `}
                      disabled={!watchCode}
                      onClick={handleSubmit(onSubmit)}>
                      {t('Common.verify')}
                    </ButtonSubmit>
                  </div>
                  <div className='col-12 col-sm-6 col-md-6 mb-3 btn_return'>
                    <button
                      className={`btn w-100 font-weight-700 border app-btn bg-white bg-while `}
                      onClick={() => navigate(`${newPathUser}`)}>
                      {t('Common.return')}
                    </button>
                  </div>
                </div>
              </InputWrapper>
            </div>
          </div>
        </div>
      </LayoutCreatorRight>
    </>
  );
}
