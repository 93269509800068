import { ImgCustom, LayoutCreatorRight, PopupConfirmFan, PopupLoading } from 'components';
import { newPathUser } from 'constants/layout/constant';
import useRedirect from 'hooks/useRedirect';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userGetSettingNotification } from 'store/actions/notification';
import './styles.scss';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { resetDataInfoOther, resetIDHomePage } from 'store/actions/usersNew';
import logo_coco_fans from 'images/Logo.png';
import ButtonSubmit from 'components/Button/ButtonSubmit';
import { InputWrapper } from 'components/FormLogin';
import { useForm } from 'react-hook-form';
import { ENV } from 'utils';
import Storage from 'utils/storage';
import { pushNotify } from 'components/Notification';
import { CopyLinkIcon } from 'images';

export default function Index() {
  const dispatch = useDispatch();
  const { loading, setting } = useSelector((state) => state.notification);
  const { dataUser } = useSelector((state) => state.users);
  const { t } = useTranslation();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLoadingFollow, setIsLoadingFollow] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });
  const [data, setData] = useState([]);

  const [copySuccess, setCopySuccess] = useState(false);
  const copyLinkFunc = () => {
    navigator.clipboard.writeText(data?.secret);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 1500);
  };

  const API = {
    getData: () => `${ENV}/enable-2fa`,
    postData: () => `${ENV}/verify-2fa`,
  };

  const watchCode = watch('code', '');
  const onSubmit = async (data) => {
    setIsLoadingFollow(true);
    try {
      await fetch(`${ENV}/verify-2fa?verify_type=1&code=${data?.code}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
        },
      })
        .then((rs) => rs.json())
        .then((data) => {
          setIsLoadingFollow(false);
          if (data?.success === true) {
            pushNotify('success', t('Creator.cardManagement.sercuSuccess'));
            navigate(`${newPathUser}notification/setting`);
          } else {
            pushNotify('error', t('Creator.cardManagement.sercuFail'));
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    dispatch(userGetSettingNotification());
  }, []);

  //* Error
  const error = useSelector((state) => state.notification.error);
  useRedirect(error);

  useEffect(() => {
    getDataSer();
  }, []);

  const getDataSer = async () => {
    await fetch(API.getData(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
      },
    })
      .then((rs) => rs.json())
      .then((data) => {
        setData(data?.data);
      });
  };

  return (
    <>
      {isLoadingFollow && <PopupLoading className={'popup-loading'} />}
      {isOpenModal && (
        <PopupConfirmFan text={t('Popup.configSettingSuccess')} className='popup-setting-noti'>
          <div
            className='ok btn'
            onClick={() => {
              setIsOpenModal(false);
              dispatch(resetIDHomePage());
              dispatch(resetDataInfoOther());
              navigate(`${newPathUser}`);
            }}>
            {t('Common.Ok')}
          </div>
        </PopupConfirmFan>
      )}
      <LayoutCreatorRight
        noButtonBack
        titlePage={t('Notification.setting.titleSercu')}
        loading={loading}
        className='setting-noti-layout'>
        <div className='notification-page'>
          {loading && !setting && (
            <div className='shimmer-loading'>
              <ShimmerPostDetails variant='SIMPLE' />
            </div>
          )}
          {setting && (
            <div className='setting-noti-secur' style={{}}>
              <div className='logo'>
                <ImgCustom
                  src={logo_coco_fans}
                  height={58}
                  width={260}
                  alt='logo'
                  className='logo-cocofans'
                />
              </div>
              <div className='text-des flex-column align-items-center'>
                <div>{t('Notification.setting.title1')}</div>
                <div>{t('Notification.setting.title2')}</div>
              </div>
              <div className='qr-code logo'>
                <ImgCustom src={`${data?.qrCodeUrl}`} alt='logo' className='logo-cocofans' />
              </div>
              <div className='text-note'>
                <div>{t('Notification.setting.account1')}</div>
                <di>{t('Notification.setting.account2')}</di>
              </div>
              <div className='block-account'>
                <div className='d-flex'>
                  <div>{t('Notification.setting.account')}</div>
                  <div
                    className='email-2fa'
                    style={{
                      marginLeft: '5px',
                      fontWeight: '700',
                      fontSize: '16px',
                      color: '#1a1a1a',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      maxWidth: '300px',
                      fontFamily: 'sans-serif'
                    }}>
                    Sharegram-{dataUser?.email}
                  </div>
                </div>
                <div className='d-flex'>
                  <div>{t('Notification.setting.key')}</div>
                  <div
                    style={{
                      marginLeft: '5px',
                      fontWeight: '700',
                      fontSize: '16px',
                      color: '#1a1a1a',
                      fontFamily: 'sans-serif'
                    }}>
                    {`${data?.secret}`}
                  </div>
                  <svg
                    width={20}
                    height={20}
                    viewBox='0 0 20 20'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    style={{ marginLeft: '10px', cursor: "pointer" }}
                    onClick={copyLinkFunc}>
                    <path
                      d='M13.125 13.125H16.875V3.125H6.875V6.875'
                      stroke='#1A1A1A'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M13.125 6.875H3.125V16.875H13.125V6.875Z'
                      stroke='#1A1A1A'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                  {copySuccess && (
                    <div className='copy-success'>{t('Popup.messageCopy')}</div>
                  )}
                </div>
              </div>
              <div className='text-note1'>{t('Notification.setting.title4')}</div>
              <div className='block-code'>
                <InputWrapper
                  id='fsite-account_number'
                  required
                  error={errors?.code ? errors?.code?.message : ''}>
                  <input
                    type='text'
                    className='fsite-input'
                    placeholder={t('Notification.setting.placeholderCode')}
                    name='code'
                    maxLength={6}
                    {...register('code', {
                      required: 'ValidateMsg.codeNumberRequired',
                      pattern: {
                        value: /^[0-9]+$/,
                        message: 'ProfileSetting.correctCodeNumber',
                      },
                    })}
                  />
                </InputWrapper>
              </div>
              <div style={{ marginTop: '32px' }}>
                <InputWrapper>
                  <div className='row wrapper__btn-comfirm'>
                    <div className='col-12 col-sm-6 col-md-6 mb-3 btn_keep'>
                      <ButtonSubmit
                        className={`btn bg-red w-100 font-weight-700 text-white app-btn `}
                        disabled={!watchCode}
                        onClick={handleSubmit(onSubmit)}>
                        {t('Common.verify')}
                      </ButtonSubmit>
                    </div>
                    <div className='col-12 col-sm-6 col-md-6 mb-3 btn_return'>
                      <button
                        className={`btn w-100 font-weight-700 border app-btn bg-white bg-while `}
                        onClick={() => navigate('/notification/setting')}>
                        {t('Common.return')}
                      </button>
                    </div>
                  </div>
                </InputWrapper>
              </div>
            </div>
          )}
        </div>
      </LayoutCreatorRight>
    </>
  );
}
