import { getEnv } from 'configs/env';
import { configParamsGet, configParamsPostJSON, configParamsPUT, ENV } from '../../utils';
import { callApi } from '../../utils/request';
import Storage from '../../utils/storage';
import { TYPES } from './index';

export const updateStatus = (status, statusInfo) => ({
  type: status,
  data: statusInfo,
});

const getListMyPage = (page = 1, limit = 5) => {
  const url = `${getEnv(
    'REACT_APP_API_SERVER'
  )}/fan/followed-posts?page=${page}&limit=${limit}`;
  const configParams = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.LIST_MY_PAGE_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.LIST_MY_PAGE_SUCCESS, {
            data: response,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.LIST_MY_PAGE_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const postLike = (id, handleSuccess) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/posts/${id}/likes`;
  const configParams = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.LIKE_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.LIKE_SUCCESS, {
            data: response.data,
          })
        );
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.LIKE_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const postLikeBookmark = (post) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/posts/${post.id}/likes`;
  const configParams = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.LIKE_BOOKMARK_SUCCESS, post));
    callApi(url, configParams, null, null, (error) => {
      dispatch(updateStatus(TYPES.LIKE_FAILED, { err: error.errors }));
      dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
    });
  };
};

const postBookMark = (id, handleSuccess) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/posts/${id}/bookmarks`;
  const configParams = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.BOOK_MARK_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.BOOK_MARK_SUCCESS, {
            data: response.data,
          })
        );
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.BOOK_MARK_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const postComment = (id, data) => {
  const formData = new FormData();
  formData.append('parent_id', data.parent_id);
  formData.append('content', data.content);
  const url = `${getEnv('REACT_APP_API_SERVER')}/posts/${id}/comments`;
  const configParams = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
    body: formData,
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.POST_COMMENT_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.POST_COMMENT_SUCCESS, {
            data: response,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.POST_COMMENT_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const postLikeComment = (id) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/comments/${id}/likes`;
  const configParams = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.LIKE_COMMENT_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.LIKE_COMMENT_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.LIKE_COMMENT_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const deleteComment = (id) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/comment/${id}/delete`;
  const configParams = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.DELETE_COMMENT_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.DELETE_COMMENT_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.DELETE_COMMENT_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const editComment = (id, data) => {
  const formData = new FormData();
  formData.append('content', data.content);
  const url = `${getEnv('REACT_APP_API_SERVER')}/comments/${id}/`;
  const configParams = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
    body: formData,
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.EDIT_COMMENT_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.EDIT_COMMENT_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.EDIT_COMMENT_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const getPostDetail = (id, handleSuccess) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/posts/${id}`;
  const configParams = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_POST_DETAIL_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_POST_DETAIL_SUCCESS, {
            data: response,
          })
        );
        handleSuccess && handleSuccess(response?.data);
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_POST_DETAIL_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const getListComment = (id) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/posts/${id}/comments`;
  const configParams = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_LIST_COMMENT_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_LIST_COMMENT_SUCCESS, {
            data: response,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_LIST_COMMENT_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

/* START CREATOR */
const creatorGetPostDetailNew = (id, params, handleSuccess) => {
  let url = `${getEnv('REACT_APP_API_SERVER')}/users/posts/${id}?`;
  if (params) {
    Object.keys(params).map((key) => {
      url += key + '=' + params[key] + '&';
    });
  }
  const configParams = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.CREATOR_GET_POST_DETAIL_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.CREATOR_GET_POST_DETAIL_SUCCESS, {
            data: response,
          })
        );
        handleSuccess && handleSuccess(response.data);
      },
      (error) => {
        dispatch(updateStatus(TYPES.CREATOR_GET_POST_DETAIL_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

/* START CREATOR */
const creatorGetPostDetail = (id, is_my_post = false, handleSuccess) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/posts/${id}`;
  const configParams = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.CREATOR_GET_POST_DETAIL_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.CREATOR_GET_POST_DETAIL_SUCCESS, {
            data: response,
          })
        );
        handleSuccess && handleSuccess(response.data);
      },
      (error) => {
        dispatch(updateStatus(TYPES.CREATOR_GET_POST_DETAIL_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};
const getPostDetailNoStore = ({ id, onSuccess = () => {}, onFailed = () => {} }) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/posts/${id}`;
  const configParams = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        onSuccess(response.data);
      },
      (error) => {}
    );
  };
};
const creatorGetListComment = (id) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/creator/v1/get-comment-by-posting-id/${id}`;
  const configParams = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.CREATOR_GET_LIST_COMMENT_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.CREATOR_GET_LIST_COMMENT_SUCCESS, {
            data: response,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.CREATOR_GET_LIST_COMMENT_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

// const creatorPostArticle = (data, type, handleSuccess, handleFailed) => {
//   const {
//     condition_radio,
//     content,
//     main_video,
//     number_part,
//     number_premium_ticket,
//     package_ids = [],
//     paid_package_radio,
//     price,
//     thumbnail,
//     time_sell_radio,
//     trailer,
//     title,
//     public_time,
//     unpublic_time,
//     images = [],
//     enable_comment,
//     compressed_file,
//     all_package,
//     in_24h_flag,
//     calendar_flag,
//   } = data;

//   const formData = new FormData();
//   if (+type === 1) {
//     formData.append('title', title.toString());
//     formData.append('content', content.toString());
//     formData.append('enable_comment', enable_comment ? 1 : 0);
//     formData.append('type', type);
//     formData.append('all_package', all_package);
//     formData.append('in_24h_flag', in_24h_flag);
//     formData.append('calendar_flag', calendar_flag);
//     if (package_ids.length > 0) {
//       for (var i = 0; i < package_ids.length; i++) {
//         formData.append('package_ids[]', +package_ids[i].id);
//       }
//     }
//     main_video && formData.append('main_video', main_video);
//     if (images?.length > 0) {
//       for (var i = 0; i < images.length; i++) {
//         formData.append('images[]', images[i]);
//       }
//     }
//     public_time && formData.append('public_time', public_time);
//     unpublic_time && formData.append('unpublic_time', unpublic_time);
//   } else {
//     formData.append('title', title);
//     formData.append('content', content);
//     formData.append('price', +price);
//     formData.append('enable_comment', 1);
//     formData.append('type', type);
//     formData.append('all_package', all_package);
//     formData.append('calendar_flag', calendar_flag);
//     number_part && formData.append('number_part', number_part);
//     main_video && formData.append('main_video', main_video);
//     trailer && formData.append('trailer', trailer);
//     thumbnail && formData.append('thumbnail', thumbnail);
//     compressed_file && formData.append('compressed_file', compressed_file);
//     if (images?.length > 0) {
//       for (var i = 0; i < images.length; i++) {
//         formData.append('images[]', images[i]);
//       }
//     }
//     if (+paid_package_radio === 1 || paid_package_radio === '1') {
//       for (var i = 0; i < package_ids.length; i++) {
//         formData.append('package_ids[]', +package_ids[i].id);
//       }
//     }
//     if (+condition_radio === 1 || condition_radio === '1')
//       formData.append('number_premium_ticket', number_premium_ticket);
//     if (+time_sell_radio === 1 || time_sell_radio === '1')
//       public_time && formData.append('public_time', public_time);
//     if (+time_sell_radio === 1 || time_sell_radio === '1')
//       unpublic_time && formData.append('unpublic_time', unpublic_time);
//   }
//   const url = `${getEnv('REACT_APP_API_SERVER')}/creator/post`;
//   const configParams = {
//     method: 'POST',
//     headers: {
//       Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
//     },
//     body: formData,
//   };

//   return (dispatch) => {
//     dispatch(updateStatus(TYPES.CREATOR_POST_ARTICLE_REQUEST));
//     callApi(
//       url,
//       configParams,
//       null,
//       async (response) => {
//         await dispatch(
//           updateStatus(TYPES.CREATOR_POST_ARTICLE_SUCCESS, {
//             data: response.data,
//           })
//         );
//         handleSuccess && handleSuccess(response.data);
//       },
//       (error) => {
//         dispatch(updateStatus(TYPES.CREATOR_POST_ARTICLE_FAILED, { err: error.errors }));
//         dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
//         handleFailed && handleFailed();
//       }
//     );
//   };
// };

const creatorPostArticle = (data, type, handleSuccess, handleFailed) => {
  const {
    condition_radio,
    content,
    main_video,
    number_part,
    package_ids = [],
    paid_package_radio,
    thumbnail,
    time_sell_radio,
    trailer,
    title,
    public_time,
    unpublic_time,
    images = [],
    enable_comment,
    compressed_file,
    short_video,
  } = data;

  let newData = {};

  if (+type === 1 || +type === 4) {
    newData = {
      ...data,
      // title: title.toString(),
      // content: content.toString(),
      enable_comment: enable_comment ? 1 : 0,
      package_ids: package_ids.map((item) => item?.id),
      type: type,
    };
    !public_time && delete newData.public_time;
    !unpublic_time && delete newData.unpublic_time;
    !images?.length && delete newData.images;
    !main_video && delete newData.main_video;
    !short_video && delete newData.short_video;
    !thumbnail && delete newData.thumbnail;
  } else {
    newData = {
      ...data,
      title: title?.toString(),
      content: content?.toString(),
      enable_comment: 1,
      package_ids: package_ids?.map((item) => item?.id),
      type: type,
    };
    !public_time && delete newData.public_time;
    !unpublic_time && delete newData.unpublic_time;
    !number_part && delete newData.number_part;
    !main_video && delete newData.main_video;
    !trailer && delete newData.trailer;
    !compressed_file && delete newData.compressed_file;
    +paid_package_radio !== 1 && delete newData.package_ids;
    if (+time_sell_radio !== 1) {
      delete newData.public_time;
      delete newData.unpublic_time;
    }
  }
  const url = +type === 1 || +type === 4 ? `${ENV}/posts/post` : `${ENV}/posts/single-sale`;

  return (dispatch) => {
    dispatch(updateStatus(TYPES.CREATOR_POST_ARTICLE_REQUEST));
    callApi(
      url,
      configParamsPostJSON(newData),
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.CREATOR_POST_ARTICLE_SUCCESS, {
            data: response.data,
          })
        );
        handleSuccess && handleSuccess(response.data);
      },
      (error) => {
        dispatch(updateStatus(TYPES.CREATOR_POST_ARTICLE_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        handleFailed && handleFailed();
      }
    );
  };
};

const creatorEditArticle = (post_id, data, type, handleSuccess, handleFailed) => {
  const {
    condition_radio,
    creator_only,
    content,
    main_video,
    number_part,
    number_premium_ticket,
    package_ids = [],
    paid_package_radio,
    price,
    thumbnail,
    time_sell_radio,
    trailer,
    title,
    public_time,
    unpublic_time,
    images = [],
    enable_comment,
    compressed_file,
    all_package,
    calendar_flag,
    short_video,
    short_images,
    contract_ids,
    discount,
    discount_start,
    discount_end,
  } = data;
  let DATA_EDIT = {};
  if (+type === 1 || +type === 4) {
    DATA_EDIT = {
      ...data,
      contract_ids,
      content: content?.toString(),
      enable_comment: enable_comment ? 1 : 0,
      type: type,
      public_time: public_time ? public_time : undefined,
      unpublic_time: unpublic_time ? unpublic_time : undefined,
      images: images?.length > 0 ? images : undefined,
      main_video: main_video ? main_video : undefined,
      short_video: short_video ? short_video : undefined,
      short_images: short_images?.length ? short_images : undefined,
      package_ids: package_ids.length > 0 ? package_ids.map((item) => +item.id) : undefined,
      all_package: all_package,
      calendar_flag: calendar_flag,
    };
  } else {
    DATA_EDIT = {
      creator_only: creator_only,
      title: title,
      contract_ids,
      content: content,
      enable_comment: 1,
      type: type,
      number_premium_ticket,
      public_time: +time_sell_radio === 1 ? public_time : undefined,
      unpublic_time:
        +time_sell_radio === 1 ? unpublic_time : undefined,
      images: images?.length > 0 ? images : [],
      package_ids:
        (+paid_package_radio === 1) && package_ids.length > 0
          ? package_ids.map((item) => item.id)
          : undefined,
      number_part: number_part,
      price: price,
      main_video: main_video && main_video,
      trailer: trailer && trailer,
      thumbnail: thumbnail && thumbnail,
      compressed_file: compressed_file && compressed_file,
      all_package: all_package,
      calendar_flag: calendar_flag,
      duration_trailer: data?.duration_trailer,
      discount,
      discount_start,
      discount_end,
    };
  }
  const url =
    +type === 1 || +type === 4
      ? `${ENV}/posts/post/${post_id}`
      : `${ENV}/posts/single-sale/${post_id}`;
  const configParams = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(DATA_EDIT),
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.CREATOR_EDIT_ARTICLE_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.CREATOR_EDIT_ARTICLE_SUCCESS, {
            data: response.data,
          })
        );
        handleSuccess && handleSuccess(response.data);
      },
      (error) => {
        dispatch(updateStatus(TYPES.CREATOR_EDIT_ARTICLE_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        handleFailed && handleFailed();
      }
    );
  };
};

const creatorDeleteArticle = (post_id, handleSuccess) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/posts/post/${post_id}`;
  const configParams = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.CREATOR_DELETE_POST_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.CREATOR_DELETE_POST_SUCCESS, {
            data: response.data,
          })
        );
        handleSuccess && handleSuccess(response.data);
      },
      (error) => {
        dispatch(updateStatus(TYPES.CREATOR_DELETE_POST_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const creatorGetDetailTimeLineArticle = (id, handleSuccess, handleFailed) => {
  return () => {
    callApi(
      `${ENV}/post-timelines/${id}`,
      configParamsGet,
      null,
      async (response) => {
        handleSuccess && handleSuccess(response.data);
      },
      (error) => {
        handleFailed && handleFailed(error);
      }
    );
  };
};

const creatorPostNotApprovedArticle = (data, handleSuccess, handleFailed) => {
  return () => {
    callApi(
      `${ENV}/posts/post`,
      configParamsPostJSON(data),
      null,
      async (response) => {
        handleSuccess && handleSuccess(response.data);
      },
      (error) => {
        handleFailed && handleFailed(error);
      }
    );
  };
};

const creatorPostTimeLineArticle = (data, handleSuccess, handleFailed) => {
  const { images, short_images, short_video } = data;
  !short_images?.length && delete data.short_images;
  !images?.length && delete data.images;
  !short_video && delete data.short_video;
  return () => {
    callApi(
      `${ENV}/post-timelines`,
      configParamsPostJSON(data),
      null,
      async (response) => {
        handleSuccess && handleSuccess(response.data);
      },
      (error) => {
        handleFailed && handleFailed(error);
      }
    );
  };
};

const creatorEditTimeLineArticle = (post_id, data, handleSuccess, handleFailed) => {
  const { images = [], short_video, short_images = [] } = data;
  !images?.length && delete data.images;
  !short_video && delete data.short_video;
  !short_images.length && delete data.short_images;
  return () => {
    callApi(
      `${ENV}/post-timelines/${post_id}`,
      configParamsPUT(data),
      null,
      async (response) => {
        (await handleSuccess) && handleSuccess(response.data);
      },
      (error) => {
        handleFailed && handleFailed(error.errors);
      }
    );
  };
};

const getCommentDetail = (post_id, page = 1, limit = 5) => {
  const url = `${getEnv(
    'REACT_APP_API_SERVER'
  )}/posts/${post_id}/comments?page=${page}&limit=${limit}`;
  const configParams = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_COMMENT_DETAIL_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_COMMENT_DETAIL_SUCCESS, {
            data: response,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_COMMENT_DETAIL_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};
const getListCommentDetail = ({
  id,
  page = 1,
  limit = 5,
  onSuccess = () => {},
  onFailed = () => {},
  adminToken
}) => {
  const url = `${getEnv(
    'REACT_APP_API_SERVER'
  )}/posts/${id}/comments?page=${page}&limit=${limit}`;
  const token = Storage.get('USER_ACCESS_TOKEN');
  const configParams = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${adminToken ?? token}`,
    },
  };

  return () => {
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        onSuccess(response);
      },
      (error) => {
        onFailed(error);
      }
    );
  };
};
const getReplyListCommentDetail = ({
  id,
  page = 1,
  limit = 5,
  onSuccess = () => {},
  onFailed = () => {},
  adminToken
}) => {
  const url = `${getEnv(
    'REACT_APP_API_SERVER'
  )}/comments/${id}/comments?page=${page}&limit=${limit}`;
  const token = Storage.get('USER_ACCESS_TOKEN');
  const configParams = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${adminToken ?? token}`,
    },
  };

  return () => {
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        onSuccess(response);
      },
      (error) => {
        onFailed(error);
      }
    );
  };
};

const editCommentDetailReply = (id, handleSuccess) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/comments/${id}?filter_words=false`;
  const configParams = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        // await dispatch(
        //   updateStatus(TYPES.EDIT_COMMENT_DETAILS_SUCCESS, {
        //     data: response.data,
        //   })
        // );
        handleSuccess && handleSuccess(response);
      },
      (error) => {}
    );
  };
};
const putEditReplyComment = ({ id, data, onSuccess = () => {} }) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/comments/${id}`;
  const configParams = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  return (dispatch) => {
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        onSuccess(response);
      },
      (error) => {}
    );
  };
};

const getEditCommentDetail = ({ id, onSuccess = () => {}, onFailed = () => {} }) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/comments/${id}?filter_words=false`;
  const configParams = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        onSuccess(response);
      },
      (error) => {
        onFailed(error);
      }
    );
  };
};

const hideCommentPost = (id, onSuccess = () => {}, onFailed = () => {}) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/comments/${id}/hide`;
  const configParams = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
      'Content-Type': 'application/json',
    },
  };

  return (dispatch) => {
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        onSuccess(response);
      },
      (error) => {
        onFailed(error);
      }
    );
  };
};
const getReplyCommentDetail = (comment_id, page = 1, limit = 5) => {
  const url = `${getEnv(
    'REACT_APP_API_SERVER'
  )}/comments/${comment_id}/comments?page=${page}&limit=${limit}`;
  const configParams = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_REPLY_COMMENT_DETAIL_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_REPLY_COMMENT_DETAIL_SUCCESS, {
            data: response,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_REPLY_COMMENT_DETAIL_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const collapseReplyCommentDetail = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.COLLAPSE_REPLY_COMMENT_DETAIL_SUCCESS, payload));
  };
};

const postCommentDetail = (id, data) => {
  const formData = new FormData();
  formData.append('content', data.content);
  const url = `${getEnv('REACT_APP_API_SERVER')}/posts/${id}/comments`;
  const configParams = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
    body: formData,
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.POST_COMMENT_DETAIL_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.POST_COMMENT_DETAIL_SUCCESS, {
            data: response.data,
          })
        );
        dispatch(getCommentDetail(id));
      },
      (error) => {
        dispatch(updateStatus(TYPES.POST_COMMENT_DETAIL_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const postRepCommentDetail = (id, data) => {
  const formData = new FormData();
  formData.append('content', data.content);
  const url = `${getEnv('REACT_APP_API_SERVER')}/comments/${id}/comments`;
  const configParams = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
    body: formData,
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.POST_REPLY_COMMENT_DETAIL_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.POST_REPLY_COMMENT_DETAIL_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.POST_REPLY_COMMENT_DETAIL_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const deleteCommentDetail = (id) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/comments/${id}`;
  const configParams = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.DELETE_COMMENT_DETAIL_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.DELETE_COMMENT_DETAIL_SUCCESS, {
            data: {
              data: response.data,
              payload: id,
            },
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.DELETE_COMMENT_DETAIL_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const deleteReplyCommentDetail = (id, post_id, onSuccess = () => {}) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/comments/${id}`;
  const configParams = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.DELETE_REPLY_COMMENT_DETAIL_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.DELETE_REPLY_COMMENT_DETAIL_SUCCESS, {
            data: {
              data: response.data,
              payload: id,
              postId: post_id,
            },
          })
        );
        onSuccess(response);
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.DELETE_REPLY_COMMENT_DETAIL_FAILED, { err: error.errors })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const editCommentDetail = (id, data) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/comments/${id}`;
  const configParams = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.EDIT_COMMENT_DETAIL_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.EDIT_COMMENT_DETAIL_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.EDIT_COMMENT_DETAIL_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};
const editReplyCommentDetail = (id, data) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/comments/${id}`;
  const configParams = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.EDIT_REPLY_COMMENT_DETAIL_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.EDIT_REPLY_COMMENT_DETAIL_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.EDIT_REPLY_COMMENT_DETAIL_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const likeCommentDetail = (id) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/comments/${id}/likes`;
  const configParams = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.LIKE_COMMENT_DETAIL_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.LIKE_COMMENT_DETAIL_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.LIKE_COMMENT_DETAIL_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const likeReplyCommentDetail = (id) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/comments/${id}/likes`;
  const configParams = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.LIKE_REPLY_COMMENT_DETAIL_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.LIKE_REPLY_COMMENT_DETAIL_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.LIKE_REPLY_COMMENT_DETAIL_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const postLikeDetail = (id) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/posts/${id}/likes`;
  const configParams = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.LIKE_POST_DETAIL_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.LIKE_POST_DETAIL_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.LIKE_POST_DETAIL_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const postBookMarkDetail = (id) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/posts/${id}/bookmarks`;
  const configParams = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.BOOK_MARK_DETAIL_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.BOOK_MARK_DETAIL_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.BOOK_MARK_DETAIL_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const postFollowCommentDetail = (id, onRequest = () => {}, onFinally = () => {}) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/users/${id}/following`;
  const configParams = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    onRequest();
    dispatch(updateStatus(TYPES.FOLLOW_USER_COMMENT_DETAIL_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.FOLLOW_USER_COMMENT_DETAIL_SUCCESS, {
            data: response.data,
          })
        );
        onFinally();
      },
      (error) => {
        onFinally();
        dispatch(updateStatus(TYPES.FOLLOW_USER_COMMENT_DETAIL_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};
const postFollowCommentDetailNoApi = (id, data) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.FOLLOW_USER_COMMENT_DETAIL_REQUEST));
    setTimeout(() => {
      dispatch(
        updateStatus(TYPES.FOLLOW_USER_COMMENT_DETAIL_SUCCESS, {
          data,
        })
      );
    }, 50);
  };
};
const postFollowReplyCommentDetail = (id, onRequest = () => {}, onFinally = () => {}) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/users/${id}/following`;
  const configParams = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    onRequest();
    dispatch(updateStatus(TYPES.FOLLOW_USER_REPLY_COMMENT_DETAIL_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.FOLLOW_USER_REPLY_COMMENT_DETAIL_SUCCESS, {
            data: response.data,
          })
        );
        onFinally();
      },
      (error) => {
        onFinally();
        dispatch(
          updateStatus(TYPES.FOLLOW_USER_REPLY_COMMENT_DETAIL_FAILED, { err: error.errors })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const buyPostContent = (id) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/fan/post/${id}/buy`;
  const configParams = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.BUY_POST_CONTENT_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.BUY_POST_CONTENT_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.BUY_POST_CONTENT_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

export const resetActionMyPage = () => (dispatch) => {
  dispatch(updateStatus('RESET_ACTION_MY_PAGE'));
};

export const settingMinimalMenu = () => (dispatch) => {
  dispatch(updateStatus('SET_MINIMAL_MENU'));
};
export const resetDetailPost = () => (dispatch) => {
  dispatch(updateStatus('RESET_DETAIL_POST'));
};

// export const logUserProfile = () => (dispatch) => {
//   dispatch(updateStatus('SET_LOG_USER'));
// };

export const logUserProfile = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus('SET_LOG_USER', payload));
  };
};

/* END CREATOR */
export {
  getListMyPage,
  postLike,
  postBookMark,
  postComment,
  postLikeComment,
  deleteComment,
  editComment,
  creatorGetPostDetail,
  creatorGetListComment,
  getPostDetail,
  getListComment,
  creatorPostArticle,
  creatorEditArticle,
  creatorDeleteArticle,
  getCommentDetail,
  getReplyCommentDetail,
  postCommentDetail,
  collapseReplyCommentDetail,
  postRepCommentDetail,
  postLikeBookmark,
  deleteCommentDetail,
  deleteReplyCommentDetail,
  editCommentDetail,
  editReplyCommentDetail,
  likeCommentDetail,
  likeReplyCommentDetail,
  postLikeDetail,
  postBookMarkDetail,
  postFollowCommentDetail,
  postFollowReplyCommentDetail,
  buyPostContent,
  creatorPostTimeLineArticle,
  creatorGetDetailTimeLineArticle,
  creatorEditTimeLineArticle,
  creatorPostNotApprovedArticle,
  creatorGetPostDetailNew,
  getPostDetailNoStore,
  getListCommentDetail,
  getReplyListCommentDetail,
  editCommentDetailReply,
  putEditReplyComment,
  getEditCommentDetail,
  postFollowCommentDetailNoApi,
  hideCommentPost,
};
