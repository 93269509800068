/* eslint-disable import/no-anonymous-default-export */

import { TYPES } from '../actions';
const initialState = {
  status: null,
  loading: true,
  error: null,
  data: {},
  listNotifyManager: [],
  listNotifyFan: [],
  detail: {},
  setting: null,
  dataNotify: {},
  dataNoMypage: {},
  idNoti1: {},
  idNoti2: {},
  initPageNoti: 0,
  tabNoti: null,
  settingSercutity: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_DETAIL_NOTICATION_REQUEST:
    case TYPES.GET_NOTICATION_MYPAGE_REQUEST:
    case TYPES.LIST_NOTIFICATION_REQUEST:
    case TYPES.GET_DETAIL_NOTIFICATION_REQUEST:
    case TYPES.SEEN_NOTIFICATION_REQUEST:
    case TYPES.GET_SETTING_NOTIFICATION_REQUEST:
    case TYPES.SETTING_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TYPES.SEEN_NOTIFICATION_SUCCESS:
      return {
        ...state,
        data: action.data.data,
        loading: false,
      };
    case TYPES.GET_SETTING_NOTIFICATION_SUCCESS:
      return {
        ...state,
        setting: action.data.data,
        loading: false,
      };
    case TYPES.SETTING_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        settingSercutity: action.data.data,
      };
    case TYPES.GET_DETAIL_NOTIFICATION_SUCCESS:
      return {
        ...state,
        detail: action.data.data,
        loading: false,
      };
    case TYPES.LIST_NOTIFICATION_SUCCESS:
      const currentPage = action?.data?.data?.pagination?.current_page;
      const newState = {
        ...state,
        data: action.data.data,
        loading: false,
        initPageNoti: action?.data?.data?.pagination?.current_page
      };
      if (action?.data?.type === 1) {
        newState.listNotifyManager =
          currentPage > 1
            ? [...state.listNotifyManager, ...action.data.data.data]
            : action.data.data.data;
      } else {
        newState.paginationNoti = action?.data?.data?.pagination?.current_page;
        newState.listNotifyFan =
          currentPage > 1
            ? [...state.listNotifyFan, ...action.data.data.data]
            : action.data.data.data;
      }

      return newState;
    case TYPES.GET_DETAIL_NOTICATION_SUCCESS:
      return {
        ...state,
        dataNotify: action.data.data,
        loading: false,
      };
    case TYPES.GET_NOTICATION_MYPAGE_SUCCESS:
      return {
        ...state,
        dataNoMypage: action.data.data,
        loading: false,
      };
    case TYPES.GET_DETAIL_NOTICATION_FAILED:
      return {
        ...state,
        loading: false,
      };
    case TYPES.GET_NOTICATION_MYPAGE_FAILED:
      return {
        ...state,
        loading: false,
      };
    case TYPES.GET_DETAIL_NOTIFICATION_FAILED:
    case TYPES.SEEN_NOTIFICATION_FAILED:
    case TYPES.GET_SETTING_NOTIFICATION_FAILED:
    case TYPES.SETTING_NOTIFICATION_FAILED:
    case TYPES.LIST_NOTIFICATION_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
      };
    case 'RESET_NOTIFI_SETTING':
      return {
        ...state,
        //listNotifyManager: [],
        //listNotifyFan: [],
        detail: {},
        setting: null,
        dataNotify: {},
      };

    case 'RESET_NOTI_DETAIL':
      return {
        ...state,
        dataNotify: {},
      };
    case 'ID_NOTI1':
      return {
        ...state,
        idNoti1: action.data
      };
    case 'RESET_ID_NOTI1': 
      return {
        ...state,
        idNoti1: {},
        listNotifyFan: [],
        initPageNoti: 0
      }
    case 'ID_NOTI1_ADMIN':
      return {
        ...state,
        idNoti2: action.data
      };
    case 'RESET_ID_NOTI1_ADMIN': 
      return {
        ...state,
        idNoti2: {},
        listNotifyManager: [],
      }
    case 'TAB_NOTI':
      return {
        ...state,
        tabNoti: action.data
      };
    case 'RESET_TAB_NOTI':
      return {
        ...state,
        tabNoti: null
      };
    default:
      return state;
  }
};
