/* eslint-disable react-hooks/exhaustive-deps */
import { ImgCustom, PopupConfirmFan, PopupLoading } from 'components';
import { InputWrapper } from 'components/FormLogin';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { authentication, sendOtpWithEmail, verifyOtpWithEmail } from 'store/actions/users';
import { patternNoFirstSpacing, STORAGE } from 'utils';
import Storage from 'utils/storage.js';
import HeaderListIdolV2 from 'components/HeaderV2/HeaderListIdolV2';
import FooterListIdolV2 from 'components/FooterV2/FooterListIdolV2';
import HomeQuickViewImageSigin from 'components/HomeQuickViewImageSigin';
import IdolDemoN1 from 'images/img-slice-n1.png';
import IdolDemoN2 from 'images/img-slice-n2.png';
import IdolDemoN3 from 'images/img-slice-n3.png';

import './SignIn.scss';
import { useNavigate } from 'react-router-dom';
const Authentication = () => {
  const dispatch = useDispatch();
  const [typeVerify, setTypeVefiry] = useState('code');
  const [code, setCode] = useState();
  const { t } = useTranslation();
  const { loading, error } = useSelector((state) => state.users);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  const navigate = useNavigate();
  const watchCode = watch('code', '');
  const isDisabledButton = !watchCode;
  const email = Storage.get('email');
  const token = Storage.get('USER_REQUEST_TOKEN');

  useEffect(() => {
    if (!token || !email) {
      navigate(`/sign-in`);
    }
  }, [token, email])

  useEffect(() => {
    setValue('code', '')
  }, [typeVerify])

  const onSendOtp = () => {
    dispatch(
      sendOtpWithEmail()
    )
  }

  const onSubmit = (data) => {
    const newData = {
      code: data.code,
    };
    setCode(data.code);
    switch (typeVerify) {
      case 'code':
        dispatch(
          authentication(newData)
          // authentication(newData, (result) => {
          //   if(result === 'VERIFY_2FA_FAIL') {
          //     pushNotify('error', t('Creator.cardManagement.sercuFail'));
          //   } else if (result === 'F2A_VERIFY_EXPIRED') {
          //     navigate(`/sign-in`);
          //   }
          // })
        )
        return
      case 'email':
        dispatch(
          verifyOtpWithEmail(newData)
        )
        return
      default:
        return
    }
  };

  const initialImages = [
    {
      url: IdolDemoN1,
    },
    {
      url: IdolDemoN2,
    },
    {
      url: IdolDemoN3,
    },
  ];

  const UISignin = (
    <>
      <div
        className='block-div-signin sign-in-wrapper'
        style={{ backgroundColor: 'transparent' }}>
        <HeaderListIdolV2 />
        <div className='container-div'>
          <div className='container-left'>
            <HomeQuickViewImageSigin className='mt-50' initialImages={initialImages} />
          </div>
          <div className='container-login'>
            <div className='login-form'>
              {typeVerify === 'code' && (
                <form className='form' onSubmit={(e) => e.preventDefault()} autoComplete='off'>
                  <div className='d-center title text-center'>
                    <span>{t('SignIn.authenticator')}</span>
                  </div>
                  <div className='' style={{ marginBottom: '20px', textAlign: "center" }}>
                    {t('SignIn.titleAu')}
                  </div>
                  <InputWrapper
                    id='fsite-account_number'
                    label={t('SignIn.titleAuth')}
                    required
                    error={errors?.code ? errors?.code?.message : ''}>
                    <input
                      type='text'
                      className='fsite-input'
                      placeholder={t('Notification.setting.placeholderCodeOpt')}
                      name='code'
                      maxLength={6}
                      {...register('code', {
                        required: 'ValidateMsg.codeNumberRequired',
                        pattern: {
                          value: /^[0-9]+$/,
                          message: 'ProfileSetting.correctCodeNumber',
                        },
                      })}
                    />
                  </InputWrapper>
                  <button
                    style={{ marginTop: '32px' }}
                    className={`btn ${isDisabledButton ? `disabled` : `active`}`}
                    type='submit'
                    disabled={isDisabledButton}
                    onClick={handleSubmit(onSubmit)}>
                    {t('Common.verify')}
                  </button>
                  <button
                    className={`btn register cancel`}
                    type='button'
                    onClick={() => {
                      Storage.remove(STORAGE.TOKEN_TWITTER);
                      Storage.remove(STORAGE.SERECT_TOKEN_TWITTER);
                      window.location.href = '/sign-in';
                    }}>
                    {t('Common.back')}
                  </button>
                  <div className='try-email-method'>
                    <span
                      onClick={() => {
                        onSendOtp()
                        setTypeVefiry('email')
                      }}
                    >
                      {t('SignIn.tryEmailMethod')}
                    </span>
                  </div>
                </form>
              )}
              
              {typeVerify === 'email' && (
                <form className='form' onSubmit={(e) => e.preventDefault()} autoComplete='off'>
                  <div className='d-center title text-center'>
                    <span>{t('SignIn.emailVerification')}</span>
                  </div>
                  <div className='' style={{ marginBottom: '20px', textAlign: 'center' }}>
                    {t('SignIn.descriptionEmailVerification', { email })}
                  </div>
                  <InputWrapper
                    id='fsite-account_number'
                    label={t('SignIn.titleAuth')}
                    required
                    error={errors?.code ? errors?.code?.message : ''}>
                    <input
                      type='text'
                      className='fsite-input'
                      placeholder={t('Notification.setting.placeholderCodeOpt')}
                      name='code'
                      maxLength={6}
                      {...register('code', {
                        required: 'ValidateMsg.codeNumberRequired',
                        pattern: {
                          value: /^[0-9]+$/,
                          message: 'ProfileSetting.correctCodeNumber',
                        },
                      })}
                    />
                  </InputWrapper>
                  <div className='resend'>
                    <span>{t('SignIn.didntReceiveCode')} </span>
                    <span onClick={() => onSendOtp()}>{t('SignIn.resend')}</span>
                  </div>
                  <button
                    style={{ marginTop: '32px' }}
                    className={`btn ${isDisabledButton ? `disabled` : `active`}`}
                    type='submit'
                    disabled={isDisabledButton}
                    onClick={handleSubmit(onSubmit)}>
                    {t('Common.verify')}
                  </button>
                  <button
                    className={`btn register cancel`}
                    type='button'
                    onClick={() => {
                      setTypeVefiry('code')
                    }}>
                    {t('Common.back')}
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>
        <FooterListIdolV2 />
      </div>
    </>
  );
  const isSSOLogin = window.sessionStorage.getItem('check_sso');
  return (
    <>
      {loading && <PopupLoading className={'popup-loading'} />}
      {/* {popupConfirmLogin && (
        <PopupConfirmFan
          isVisiblePopup={() => setPopupConfirmLogin(false)}
          colorSvg='#FF9F43'
          text={t('Popup.accoutLoginOtherBrowser')}
          className='popup-confirm'>
          <div className='btn cancel' onClick={() => setPopupConfirmLogin(false)}>
            {t('Common.buttonNo')}
          </div>
          <div className='ok btn' onClick={handleConfirmLogin}>
            {t('Common.buttonYes')}
          </div>
        </PopupConfirmFan>
      )} */}
      {isSSOLogin ? <PopupLoading className={'popup-loading'} /> : UISignin}
    </>
  );
};

export default Authentication;
