import {
  AccountSidebar,
  HeaderListIdolV2,
  LayoutCreatorRight,
  MenuSidebarCustom,
  PopupConfirmFan,
  PopupLoading,
  ToggleButton,
} from 'components';
import { newPathUser } from 'constants/layout/constant';
import useRedirect from 'hooks/useRedirect';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  userGetSettingNotification,
  userPutSettingNotification,
} from 'store/actions/notification';
import '../styles.scss';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { isMobile } from 'react-device-detect';
import { CloseIcon, MenuIcon } from 'images';
import { isAdmin, isAgency } from 'utils';
import { resetDataInfoOther, resetIDHomePage } from 'store/actions/usersNew';

export default function Index() {
  const dispatch = useDispatch();
  const { loading, setting } = useSelector((state) => state.notification);
  const { t } = useTranslation();
  const [isSetMessageArrive, setIsSetMessageArrive] = useState(false);
  const [isSetMessageArriveAuthen, setIsSetMessageArriveAuthen] = useState(false);
  const [isSetNotificationArrive, setIsSetNotificationArrive] = useState(false);
  const [isSetUserPackage, setIsSetUserPackage] = useState(false);
  const [isSetCreatorPost, setIsSetCreatorPost] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isVisiblePopUpDeletePost, setIsVisiblePopDeletePost] = useState(true);
  const [isLoadding, setIsLoadding] = useState(false);
  const navigate = useNavigate();

  const handleSettingSuccess = (params, { success }) => {
    setIsLoadding(false)
    if (+params?.verify_2fa_enabled === 1) {
      navigate(`${newPathUser}security`);
    } else {
      success && setIsOpenModal(true);
    }
  };

  useEffect(() => {
    dispatch(userGetSettingNotification());
  }, []);

  useEffect(() => {
    setIsSetMessageArriveAuthen(+setting?.verify_2fa_enabled === 1 ? true : false)
  }, [setting?.verify_2fa_enabled])

  const onSetMessageArriveAuthen = async (checked) => {
    setIsLoadding(true);
    setIsSetMessageArriveAuthen(true);
    if (!checked) {
      setIsVisiblePopDeletePost(false);
    } else {
      await dispatch(userPutSettingNotification({ verify_2fa_enabled: checked ? 1 : 0 }, handleSettingSuccess))
    }
  };

  const onSetMessageArrive = async (checked) => {
    setIsLoadding(true);
    setIsSetMessageArrive(true);
    await dispatch(userPutSettingNotification({ message_notification: checked ? 1 : 0 }, handleSettingSuccess))
  };

  const onSetNotificationArrive = async (checked) => {
    setIsLoadding(true);
    setIsSetNotificationArrive(true);
    await dispatch(userPutSettingNotification({ admin_notification: checked ? 1 : 0 }, handleSettingSuccess))
  };

  const onSetUserPackage = async (checked) => {
    setIsLoadding(true);
    setIsSetUserPackage(true);
    await dispatch(userPutSettingNotification({ plan_notification: checked ? 1 : 0 }, handleSettingSuccess))
  };

  const onSetCreatorPost = async (checked) => {
    setIsLoadding(true);
    setIsSetCreatorPost(true);
    await dispatch(userPutSettingNotification({ follow_notification: checked ? 1 : 0 }, handleSettingSuccess))
  };

  //* Error
  const error = useSelector((state) => state.notification.error);
  useRedirect(error);

  return (
    <>
      {isLoadding && (
        <PopupLoading className={'popup-loading'} />
      )}
      {isOpenModal && (
        <PopupConfirmFan text={t('Popup.configSettingSuccess')} className='popup-setting-noti'>
          <div
            className='ok btn'
            onClick={() => {
              setIsOpenModal(false);
              dispatch(resetIDHomePage());
              dispatch(resetDataInfoOther());
              // navigate(`${newPathUser}`);
            }}>
            {t('Common.Ok')}
          </div>
        </PopupConfirmFan>
      )}
      {!isVisiblePopUpDeletePost && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopDeletePost(e)}
          colorSvg='#FF9F43'
          iconNote={true}
          className='popup-sercu'
          text={t('Popup.confirmSecurity')}>
          <div
            className='ok btn'
            onClick={async () => {
              await dispatch(userPutSettingNotification({ verify_2fa_enabled: 0 }, handleSettingSuccess))
              setIsSetMessageArriveAuthen(false);
              setIsVisiblePopDeletePost(true);
            }}>
            {t('Common.buttonYes')}
          </div>
          <div
            className='btn cancel'
            onClick={() => {
              setIsLoadding(false);
              setIsSetMessageArriveAuthen(true);
              setIsVisiblePopDeletePost(true);
            }}>
            {t('Common.buttonNo')}
          </div>
        </PopupConfirmFan>
      )}
      <LayoutCreatorRight
        noButtonBack
        titlePage={t('Notification.setting.title')}
        loading={loading}
        className='setting-noti-layout'>
        <div className='notification-page'>
          {loading && !setting && (
            <div className='shimmer-loading'>
              <ShimmerPostDetails variant='SIMPLE' />
            </div>
          )}
          {setting && (
            <div className='setting-noti'>
              <div className='title'>{t('Notification.setting.authenticator')}</div>
              <div className='toggle'>
                <label>{t('Notification.setting.authenticator2FA')}</label>
                <div>
                  <ToggleButton
                    id='message_notification_authen'
                    checked={!!isSetMessageArriveAuthen ? true : false}
                    onChange={onSetMessageArriveAuthen}
                  />
                </div>
              </div>
              <div className='title'>{t('Notification.setting.variousEmail')}</div>
              <div className='toggle'>
                <label>{t('Notification.setting.messageArrives')}</label>
                <div>
                  <ToggleButton
                    id='message_notification'
                    defaultChecked={+setting?.message_notification === 1 ? true : false}
                    onChange={onSetMessageArrive}
                  />
                </div>
              </div>
              <div className='toggle'>
                <label>{t('Notification.setting.receiceNotifi')}</label>
                <div>
                  <ToggleButton
                    id='admin_notification'
                    defaultChecked={+setting?.admin_notification === 1 ? true : false}
                    onChange={onSetNotificationArrive}
                  />
                </div>
              </div>
              <div className='toggle'>
                <label>{t('Notification.setting.subscribed')}</label>
                <div>
                  <ToggleButton
                    id='plan_notification'
                    defaultChecked={+setting?.plan_notification === 1 ? true : false}
                    onChange={onSetUserPackage}
                  />
                </div>
              </div>
              <div className='toggle'>
                <label>{t('Notification.setting.followPost')}</label>
                <div>
                  <ToggleButton
                    id='follow_notification'
                    defaultChecked={+setting?.follow_notification === 1 ? true : false}
                    onChange={onSetCreatorPost}
                  />
                </div>
              </div>
              {/* <div
                className='btn-setting'
                onClick={() => {
                  onSubmit();
                }}>
                <button type='button'>{t('Common.buttonUpdate')}</button>
              </div> */}
            </div>
          )}
        </div>
      </LayoutCreatorRight>
    </>
  );
}
