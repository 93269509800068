import { LayoutCreatorRight, PopupConfirmFan, PopupGeneral } from 'components';
import { newPathUser } from 'constants/layout/constant';
import FailIcon from 'images/FailIcon';
import LoadingIconV2 from 'images/LoadingIconV2';
import SuccessRegisterIcon from 'images/SuccessRegisterIcon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import {
  changeDefaultCard,
  clearActiveCard,
  clearCards,
  getCards,
} from 'store/actions/card';
import {
  cancelPaymentPonitVendo,
  getSettingSystem,
  paymentVendo,
  updateStatusPaymentFail,
  updateStatusPaymentVendo,
} from 'store/actions/payment';
import styled from 'styled-components';
import { ENV, PAYMENT_CONST } from 'utils';
import Storage from 'utils/storage';
import Step1ListCard from './step1ListCard';
import StepVendo2 from './step2';
import { WrapperPaymentVendoStyled } from './styled';
import { getUserInfoMe } from 'store/actions/users';

const LayoutCreatorRightStyled = styled(LayoutCreatorRight)``;

const PaymentVendoPage = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.users);
  const { idCardActive, pagination, cards, idScrollBackCardVendo } = useSelector(
    (state) => state.card
  );
  const {
    isLoadingPaymentVendo,
    loadingRequestPoint,
    paymentVendoSuccess,
    paymentVendoFail,
    messageErrorVendo,
    isLoadingVerifyToken,
    setting_system,
  } = useSelector((state) => state.payment);
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isShowPopupConfirm, setIsShowPopupConfirm] = useState(false);
  const [isShowPopupCancelPay, setIsShowPopupCancelPay] = useState(false);
  const [step, setStep] = useState(1);
  const [isStepDetail, setIsStepDetail] = useState(false); //20/03

  const [isShowModalOTP, setIsShowModalOTP] = useState({
    isShow: false,
    urlOTP: null,
  });
  const [page, setPage] = useState(pagination?.current_page ? +pagination?.current_page : 1);

  const LIMIT_PAGE = 20;
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSpin, setIsLoadingSpin] = useState(false);

  const [dataVendo] = useState(
    JSON.parse(Storage.get(PAYMENT_CONST.DATA_VENDO)) || location?.state?.dataVendo || null
  );
  const [codeVendo] = useState(Storage.get(PAYMENT_CONST.CODE_VENDO));
  const [urlRedirect] = useState(Storage.get(PAYMENT_CONST.URL_REDIRECT));
  const [creditCardId] = useState(Storage.get(PAYMENT_CONST.CREDIT_CARD_ID));
  const [dataVoucher, setDataVoucher] = useState(null);
  const [searchVoucher, setSearchVoucher] = useState('');
  const [data, setData] = useState('');
  const verifyVendo = searchParams.get('verify');
  const codeRollBack = searchParams.get('code');

  const setClearCards = () => {
    //dispatch(clearCards());
  };

  useEffect(() => {
    dispatch(getSettingSystem(11, (data) => setData(data)));
  }, []);

  useEffect(() => {
    if (idScrollBackCardVendo) {
      const idPost = document.getElementById(idScrollBackCardVendo);
      if (idPost) {
        idPost.scrollIntoView({
          block: 'center',
        });
      }
    }
  }, [idScrollBackCardVendo, step]);

  const idCheckActive = useMemo(() => {
    return +idCardActive || +location?.state?.idActive;
  }, [location?.state, idCardActive]);

  const cardPayment = useMemo(() => {
    if (cards?.length > 0) {
      return cards?.find((e) => {
        if (idCheckActive) {
          return +e?.id === idCheckActive;
        }
        return +e?.default === 1;
      });
    } else {
      return null;
    }
  }, [cards, idCheckActive]);

  useEffect(() => {
    return () => {
      dispatch(clearCards());
      dispatch(clearActiveCard());
      dispatch(updateStatusPaymentVendo());
      Storage.remove(PAYMENT_CONST.JWT_VENDO);
      Storage.remove(PAYMENT_CONST.DATA_VENDO);
    };
  }, []);

  const isHaveDataCard = useMemo(() => {
    return !!cards?.length;
  }, [cards]);

  const fetchNext = () => {
    const pages = pagination?.total / pagination?.per_page;
    if (pages > page) {
      setPage(page + 1);
      funcCallAPI(page + 1);
    }
  };

  const getCardsSuccess = (rs) => {
    setIsLoading(false);
  };

  const funcCallAPI = useCallback(
    (page) => {
      setIsLoading(true);
      setIsStepDetail(true);
      dispatch(
        getCards(
          {
            page,
            limit: LIMIT_PAGE,
          },
          getCardsSuccess
        )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (!idScrollBackCardVendo && (step === 1 || !step)) {
      funcCallAPI(1);
    }
  }, [funcCallAPI, step, idScrollBackCardVendo]);

  const handleChangeDefaultCardSuccess = (id) => {
    if (id) {
      const newCards = [];
      cards.forEach((item) => {
        if (item?.id === id) {
          newCards.push({
            ...item,
            default: 1,
          });
        } else {
          newCards.push({
            ...item,
            default: 0,
          });
        }
      });
      //setCards(newCards);
    }
    setIsLoadingSpin(false);
  };

  const handleChangeDefaultCard = (id) => {
    if (id) {
      setIsLoadingSpin(true);
      dispatch(changeDefaultCard(id, handleChangeDefaultCardSuccess));
    }
  };

  useEffect(() => {
    if (dataVendo?.settingMethod === 2) {
      setStep(2);
    } else {
      if (urlRedirect) {
        Storage.set(PAYMENT_CONST.VERIFY_VENDO, verifyVendo);
        Storage.set(PAYMENT_CONST.CODE_ROLLBACK, codeRollBack);
        navigate(`${urlRedirect}`);
      } else {
        if (!dataVendo?.point && !dataVendo?.package_payment_id) {
          navigate(`${newPathUser}purchase`);
        } else {
          setStep(dataVendo?.step || 1);
        }
      }
    }
  }, [dispatch, dataVendo, navigate, urlRedirect, verifyVendo, codeRollBack]);

  const paymentCheckWithStatus = (data) => {
    const status = data?.status;
    if (status === 5) {
      setIsShowModalOTP({
        isShow: true,
        urlOTP: data?.verification_url,
      });
    } else if (status === 3) {
      dispatch(updateStatusPaymentFail(data?.message));
    }
  };

  const handleError = () => {
    return navigate(`${newPathUser}purchase`, {
      state: {
        pathname: location.state?.pathname,
      },
    });
  };

  useEffect(() => {
    if (verifyVendo) {
      setStep(2);
      if (
        codeRollBack &&
        //creditCardId &&
        (!urlRedirect || urlRedirect === location.pathname)
      ) {
        dispatch(
          paymentVendo(
            {
              code: codeRollBack,
              //credit_card_id: creditCardId,
              payment_gateway: data || 2,
            },
            true,
            paymentCheckWithStatus,
            handleError
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, codeRollBack, creditCardId, dispatch, navigate, verifyVendo, urlRedirect]);

  const redirectToPayment = () => {
    let data = {};
    if (dataVendo?.package_payment_id) {
      data = {
        package_payment_id: dataVendo?.package_payment_id,
        price: dataVendo?.price,
        point: dataVendo?.point,
      };
    } else {
      data = {
        price: dataVendo?.price,
        point: dataVendo?.point,
      };
    }
    if (dataVendo?.settingMethod === 2) {
      navigate(`${newPathUser}payment`, {
        state: {
          ...data,
          pathname: location.state?.pathname,
        },
      });
    } else {
      if (step === 2) {
        setClearCards();
        setStep(1);
      } else {
        navigate(`${newPathUser}payment`, {
          state: {
            ...data,
            pathname: location.state?.pathname,
          },
        });
      }
    }
  };

  const renderTitlePage = useMemo(() => {
    if (step === 1 || !step) {
      return `Plan.titleListCard`;
    } else {
      if (isStepDetail) {
        return `Purchase.payment.titlePaymentConfirm`; //20/03
        //return `Purchase.payment.titlePaymentDetail`;
      }
      return `Purchase.payment.titlePaymentConfirm`;
    }
  }, [step, isStepDetail]);

  const [exchange, setExchange] = useState();

  useEffect(() => {
    getExchangeRate();
  }, []);

  const API = {
    getPoint: () => `${ENV}/exchange-rate`,
  };

  const getExchangeRate = async () => {
    await fetch(API.getPoint(), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
      },
    })
      .then((rs) => rs.json())
      .then((data) => {
        setExchange(data?.data?.rate);
      });
  };

  return (
    <>
      <LayoutCreatorRightStyled
        cb={redirectToPayment}
        className='purchase-page-header'
        titlePage={t(renderTitlePage)}>
        <WrapperPaymentVendoStyled>
          {isShowPopupConfirm && (
            <PopupConfirmFan
              isVisiblePopup={() => setIsShowPopupConfirm(null)}
              colorSvg='#FF9F43'
              iconNote
              text={t('Purchase.payment.vendo.confirmContinue')}>
              <div
                className='ok btn'
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setIsShowPopupConfirm(false);
                  navigate(`${newPathUser}payment/vendo`, {
                    state: {
                      ...location.state,
                      step: 2,
                    },
                  });
                }}>
                {t('Common.buttonYes')}
              </div>
              <div className='btn cancel' onClick={() => setIsShowPopupConfirm(false)}>
                {t('Common.buttonNo')}
              </div>
            </PopupConfirmFan>
          )}
          {isShowPopupCancelPay && (
            <PopupConfirmFan
              isVisiblePopup={() => setIsShowPopupCancelPay(null)}
              colorSvg='#FF9F43'
              iconNote
              text={t('Purchase.payment.vendo.confirmCancelPay')}>
              <div
                className='ok btn'
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setIsShowPopupConfirm(false);
                  navigate(`${newPathUser}purchase`, {
                    state: {
                      point: dataVendo?.package_payment_id ? null : dataVendo?.point,
                    },
                  });
                }}>
                {t('Common.buttonYes')}
              </div>
              <div className='btn cancel' onClick={() => setIsShowPopupCancelPay(false)}>
                {t('Common.buttonCancel')}
              </div>
            </PopupConfirmFan>
          )}
          {paymentVendoSuccess && (
            <PopupGeneral
              title={t('Purchase.payment.title')}
              className='popup-success-payment-tiger'>
              <div className='success-icon'>
                <SuccessRegisterIcon />
              </div>
              <div className='text-content'>
                <div className='title-success'>{t('Common.success')}</div>
                <div className='content'>{t('Purchase.payment.vendo.paymentSuccess')}</div>
              </div>

              <div className='button-wrapper'>
                <div
                  className='btn btn-ok'
                  onClick={() => {
                    dispatch(getUserInfoMe());
                    navigate(`${newPathUser}timeline`, { state: {} });
                  }}>
                  {t('Purchase.payment.vendo.returnHome')}
                </div>
                <div
                  className='btn btn-cancel'
                  onClick={() => {
                    dispatch(getUserInfoMe());
                    let idPost = window.localStorage.getItem('idPost');
                    if (idPost) {
                      window.localStorage.removeItem('idPost');
                      navigate(`${newPathUser}post-sale/${idPost}`);
                    } else {
                      navigate(`${newPathUser}purchase`);
                    }
                  }}>
                  {t('Common.Ok')}
                </div>
              </div>
            </PopupGeneral>
          )}
          {isShowModalOTP?.isShow && (
            <PopupGeneral
              title={t('Purchase.payment.title')}
              className='popup-success-payment-tiger'>
              <div className='success-icon'>
                <FailIcon />
              </div>
              <div className='text-content'>
                <div className='title-fail'>{t('Common.fail')}</div>
                <div className='content'></div>
                <div className='content'>{t('Purchase.payment.vendo.paymentOTPFail')}</div>
              </div>

              <div className='button-wrapper'>
                <div
                  className='btn btn-ok'
                  onClick={() => {
                    if (isShowModalOTP?.urlOTP) {
                      window.location.href = isShowModalOTP?.urlOTP;
                    }
                  }}>
                  {t('Common.buttonYes')}
                </div>
                <div
                  className='btn btn-cancel'
                  onClick={() => {
                    dispatch(
                      cancelPaymentPonitVendo(codeVendo, () =>
                        navigate(`${newPathUser}purchase`, { state: {} })
                      )
                    );
                  }}>
                  {t('Common.buttonNo')}
                </div>
              </div>
            </PopupGeneral>
          )}
          {(isLoadingPaymentVendo ||
            isLoadingVerifyToken ||
            isLoadingSpin ||
            loadingRequestPoint) && (
            <div className='layer-spinner'>
              <LoadingIconV2 className='spinner-icon' />
            </div>
          )}
          {isLoading && !isHaveDataCard ? (
            <div className='shimmer-loading'>
              <ShimmerPostDetails />
            </div>
          ) : (
            <>
              {(step === 1 || !step) && setting_system == 1 && (
                <Step1ListCard
                  isLoading={isLoading}
                  dataVendo={dataVendo}
                  idCheckActive={idCheckActive}
                  fetchNext={fetchNext}
                  handleChangeDefaultCard={handleChangeDefaultCard}
                  isHaveDataCard={isHaveDataCard}
                  cards={cards}
                  setStep={setStep}
                />
              )}
              {step === 2 && (
                <StepVendo2
                  exchange={exchange}
                  setStep={setStep}
                  setClearCards={setClearCards}
                  isStepDetail={isStepDetail}
                  setIsStepDetail={setIsStepDetail}
                  dataVendo={dataVendo}
                  cardPayment={cardPayment}
                  setIsShowPopupCancelPay={setIsShowPopupCancelPay}
                  setDataVoucher={setDataVoucher}
                  dataVoucher={dataVoucher}
                  setSearchVoucher={setSearchVoucher}
                  searchVoucher={searchVoucher}
                />
              )}
            </>
          )}
        </WrapperPaymentVendoStyled>
      </LayoutCreatorRightStyled>
    </>
  );
};

export default PaymentVendoPage;
