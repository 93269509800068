//components
import {
  Checkbox,
  CustomSelectTime,
  ImgCustom,
  LayoutCreatorRight,
  PopupConfirmFan,
  ReactCalendar,
} from 'components';
import { InputWrapper } from 'components/Input/InputWrapper';
import { pushNotify } from 'components/Notification';
import { newPathUser } from 'constants/layout/constant';
import Select from 'react-select';
import withCreatorRole from 'hocs/withCreatorRole';
import useCalendar from 'hooks/useCalendar';
import useOutsideClickCustom from 'hooks/useOutsideClickCustom';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
//store
import { creatorPostArticle, creatorPostNotApprovedArticle } from 'store/actions/myPage';
import { creatorGetListPackage } from 'store/actions/users';
//util
import {
  currentTimeToUTC,
  isValidDate,
  patternNoFirstSpacing,
  validateTimePostNormalRequired,
  getPresignedUrlS3,
  setFileNameS3,
  uploadS3ByPresigned,
  CustomOption,
  acceptedImageTypes,
  TEN_MB,
  failImgThan10Mb,
  failImgInvalid,
  CustomDropdownIndicator,
  customStylesReselect,
  checkLinkPostSale,
  decryptPath,
  BTN_REMOVE,
  sortListPackagePointAndDate,
} from 'utils';
//scss
import '../normal.scss';
import ListImgVideo from './listImgVideo';
import { listTab } from '../post-content';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import CalendarIconV2 from 'images/CalendarIconV2';
import UploadImageIcon from 'images/UploadImageIcon';
import LoadingIconV2 from 'images/LoadingIconV2';
import { isMobile } from 'react-device-detect';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { useLayoutEffect } from 'react';
import MetaTagViewer from 'components/MetaTagViewer/MetaTagViewer';
import { getInfoMetaPost } from 'store/actions/postMeta';
import { CHUNK_FILE, uploadVideoWithMulti } from '../help';
import ProcessingStatusBar from '../components/ProcessingStatusBar';
import { BlueImg } from '../styled';
import PerformerIcon from 'images/PerformerIcon';
import { useFixedPopup } from 'hooks/v2/useFixedPopup';
import PerformerPopup from '../post-content/performerPopup';
import { resetDataInfoOther, resetIDHomePage } from 'store/actions/usersNew';
//data
export const TYPE_NORMAL = 'normal';
export const TYPE_SHORT = 'short';
const PostNormal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { statusPost } = useSelector((state) => state.myPage);
  const { loading: loadingListPackage, dataUser: dataProfile } = useSelector(
    (state) => state.users
  );
  const [timePost, setTimePost] = useState('00:00');
  const [timePostCheck, setTimePostCheck] = useState(null);
  const [timeRelease, setTimeRelease] = useState('00:00');
  const [timeReleaseCheck, setTimeReleaseCheck] = useState(null);
  const [dataCheckBox, setDataCheckBox] = useState([]);
  const [listChecked, setListChecked] = useState([]);
  const [creatorListPackage, setCreatorListPackage] = useState([]);
  const [isVisiblePopUpConfirmWarningApproved, setIsVisiblePopUpConfirmWarningApproved] =
    useState(false);
  //time post & release
  const [isCheckTimePost, setIsCheckTimePost] = useState(false);
  const [isCheckTimeRelease, setIsCheckTimeRelease] = useState(false);
  const [isFailTime, setIsFailTime] = useState(false);
  //img && video
  const [imgUpload, setImgUpload] = useState([]);
  const [videoUpload, setVideoUpload] = useState();
  const [datagetPresignedUrlS3, setDatagetPresignedUrlS3] = useState({
    main_video: '',
    images: [],
  });
  const { t } = useTranslation();
  const [checkPostIn24h, setCheckPostIn24h] = useState(false);
  //loading
  const [popupLoading, setPopupLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  //settting get file m3u8
  const location = useLocation();
  const { live_title, recording, thumbnailRecord } = location?.state || {};
  const [imgRecording, setImgRecording] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  // const [isValueEmptyContent, setIsValueEmptyContent] = useState(false);

  const [postId, setPostId] = useState(null);
  const [validImgFirstTime, setValidFirstTime] = useState(false);
  const [percentUpload, setPercentUpload] = useState([]);
  const [percentThumbnail, setPercentThumbnail] = useState([]);
  const [percentImg, setPercentImg] = useState([]);
  const [openProgress, setOpenProgress] = useState(false);

  const [isShowPerformerPopup, setIsShowPerformerPopup] = useState(false);
  const [listPerformer, setListPerformer] = useState([]);

  const [showThumbnail, setShowThumbnail] = useState(false);
  const [durationVideo, setDurationVideo] = useState(0);
  const [seeNext, setSeeNext] = useState(false);
  const [typePost, setTypePost] = useState(TYPE_NORMAL);
  const [thumbnailUpload, setThumbnailUpload] = useState({ img: [], files: null });
  const [isShowPopupConfirm, setIsShowPopupConfirm] = useState(false);
  //START REACT CALENDAR
  const {
    date: date1,
    toggleCalendar: toggleCalendar1,
    onChangeDate: onChangeDate1,
    isVisibleCalendar: isVisibleCalendar1,
  } = useCalendar();
  const {
    date: date2,
    toggleCalendar: toggleCalendar2,
    onChangeDate: onChangeDate2,
    isVisibleCalendar: isVisibleCalendar2,
  } = useCalendar();

  const datePickerRef1 = useRef();
  const datePickerRef2 = useRef();

  useFixedPopup(isShowPerformerPopup);

  const isApproved = useMemo(() => {
    return dataProfile?.status === 1;
  }, [dataProfile?.status]);

  useOutsideClickCustom((e) => {
    if (isVisibleCalendar1) {
      const { className } = e?.target;
      if (
        e?.target !== datePickerRef1?.current &&
        className !== '' &&
        !className.includes('react-calendar')
      ) {
        toggleCalendar1();
      }
    }
  });
  useOutsideClickCustom((e) => {
    if (isVisibleCalendar2) {
      const { className } = e?.target;
      if (
        e?.target !== datePickerRef2?.current &&
        className !== '' &&
        !className.includes('react-calendar')
      ) {
        toggleCalendar2();
      }
    }
  });

  const top = document.getElementById('app_cocofans_0');
  useEffect(() => {
    top && top.scrollIntoView({ top: 0, left: 0, behavior: 'smooth', block: 'start' });
  }, [top]);

  useEffect(() => {
    if (isCheckTimePost) {
      const publicTime = new Date(`${date1} ${timePost}`);
      if (isValidDate(publicTime) === true) {
        if (isCheckTimeRelease) {
          const unPublicTime = new Date(`${date2} ${timeRelease}`);
          if (isValidDate(unPublicTime) === true) {
            if (publicTime > unPublicTime) {
              setIsFailTime(false);
              if (timeReleaseCheck && timePostCheck) {
                pushNotify('error', t('ValidateMsg.END_DAY_AFTER_OR_EQUAL_NOW'));
              }
            } else if (publicTime < Date.now()) {
              setIsFailTime(false);
              if (timeReleaseCheck && timePostCheck) {
                pushNotify('error', t('ValidateMsg.END_DAY_AFTER_DATE_NOW'));
              }
            } else setIsFailTime(true);
          } else setIsFailTime(false);
        } else {
          if (publicTime < Date.now()) {
            setIsFailTime(false);
            if (timePostCheck) {
              pushNotify('error', t('ValidateMsg.END_DAY_AFTER_DATE_NOW'));
            }
          } else setIsFailTime(true);
        }
      } else setIsFailTime(false);
    } else if (!isCheckTimePost && isCheckTimeRelease) {
      const unPublicTime = new Date(`${date2} ${timeRelease}`);
      if (isValidDate(unPublicTime) === true) {
        if (unPublicTime < Date.now()) {
          setIsFailTime(false);
          if (timeReleaseCheck) {
            pushNotify('error', t('ValidateMsg.END_DAY_AFTER_DATE_NOW'));
          }
        } else setIsFailTime(true);
      } else setIsFailTime(false);
    } else {
      setIsFailTime(true);
    }
  }, [
    date1,
    date2,
    timePost,
    timeRelease,
    timeReleaseCheck,
    timePostCheck,
    isCheckTimePost,
    isCheckTimeRelease,
  ]);

  useEffect(() => {
    checkPostIn24h && setIsCheckTimeRelease(false);
  }, [checkPostIn24h]);

  const optionSend = [
    { value: '0', label: t('Creator.CreateLiveStream.everyone') },
    {
      value: '1',
      label: t('Creator.PostArticle.postShort.userFollow'),
    },
  ];

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = useForm({
    mode: 'all',
    defaultValues: {
      follow_flag: optionSend[0],
      enable_comment: 1,
    },
  });
  const watchTitle = watch('title', '');
  const watchFollowFlag = watch('follow_flag', '');
  const watchContent = watch('content', '');

  const isShowErrorPackages = useMemo(() => {
    return watchFollowFlag?.value === '1' && !(imgUpload?.length > 0 || videoUpload);
  }, [watchFollowFlag?.value, imgUpload, videoUpload]);

  const isHaveImgOrVideo = useMemo(() => {
    return imgUpload?.length > 0 || videoUpload ? true : false;
  }, [imgUpload, videoUpload]);

  const disabledButton = useMemo(() => {
    if (isApproved) {
      return (
        (!isHaveImgOrVideo && !watchContent?.trim()) ||
        (isHaveImgOrVideo && !listPerformer?.length) ||
        !isFailTime ||
        popupLoading ||
        (isCheckTimePost && date1 && !timePostCheck) ||
        (isCheckTimePost && !date1) ||
        (checkPostIn24h && isCheckTimeRelease && !date2) ||
        (!checkPostIn24h && isCheckTimeRelease && date2 && !timeReleaseCheck) ||
        loading ||
        statusPost ||
        isShowErrorPackages
      );
    } else {
      return (
        !watchContent?.trim() ||
        !isFailTime ||
        popupLoading ||
        loading ||
        statusPost ||
        isShowErrorPackages
      );
    }
  }, [
    isApproved,
    isHaveImgOrVideo,
    watchContent,
    listPerformer?.length,
    isFailTime,
    popupLoading,
    checkPostIn24h,
    isCheckTimeRelease,
    date2,
    isCheckTimePost,
    date1,
    loading,
    statusPost,
    isShowErrorPackages,
    timePostCheck,
    timeReleaseCheck,
  ]);

  useEffect(() => {
    if (isHaveImgOrVideo) {
      setValidFirstTime(true);
    }
  }, [isHaveImgOrVideo]);

  useEffect(() => {
    setValue('content', live_title);
    setImgRecording(recording);
    setThumbnail(thumbnailRecord);
  }, []);

  useLayoutEffect(() => {
    if (isApproved) {
      setLoading(true);
      dispatch(
        creatorGetListPackage('', '', ({ data }) => {
          if (data) {
            setCreatorListPackage(data);
            const dataSort = sortListPackagePointAndDate(data);
            data?.length > 0 ? setDataCheckBox(dataSort) : setValue('all_package', 0);
          }
          setLoading(false);
        })
      );
    }
  }, [dispatch, isApproved, setValue]);

  //START END CALENDAR

  useEffect(() => {
    if (thumbnailUpload.img.length > 0) {
      const file = URL.createObjectURL(thumbnailUpload.img[0]);
      setThumbnailUpload((prev) => ({ ...prev, files: file }));
    }
  }, [thumbnailUpload.img]);

  //START SET HEIGHT TEXTAREA
  const handleTextarea = (e) => {
    if (e.target.scrollHeight > e.target.clientHeight) {
      e.target.style.height = e.target.scrollHeight + 'px';
    } else if (e.target.scrollHeight < e.target.clientHeight && e.target.clientHeight > 25) {
      e.target.style.height = `${e.target.clientHeight - 25}px`;
    }
    var code = e.keyCode || e.charCode;
    if (code === 13 || e.target.scrollHeight > e.target.clientHeight) {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight + 25}px`;
    }
  };
  //END SET HEIGHT TEXTAREA

  // START IMG VIDEO
  const handleInputVideo = (e) => setVideoUpload(e);
  const handleListImage = (e) => setImgUpload(e);
  // END IMG VIDEO

  // START PLAN POST
  const handleOnchangePlanPost = (e, pack) => {
    const { checked } = e.target;
    if (checked) {
      setListChecked(
        listChecked.concat({
          id: pack.id,
          title: pack.title,
          checked: true,
        })
      );
      const newData = dataCheckBox.map((item) => {
        if (item.id === pack.id) {
          return { ...item, checked: true };
        } else return item;
      });
      setDataCheckBox(newData);
    } else {
      const newData = dataCheckBox.map((item) => {
        if (item.id === pack.id) {
          return { ...item, checked: false };
        } else return item;
      });
      setDataCheckBox(newData);
      setListChecked(listChecked.filter((item) => item.id !== pack.id));
    }
  };
  const handleAllPlanPost = (e) => {
    const { checked } = e.target;
    if (checked) {
      const newData = dataCheckBox.map((item) => {
        return { ...item, checked: true };
      });
      setDataCheckBox(newData);
      setListChecked(newData);
    } else {
      const newData = dataCheckBox.map((item) => {
        return { ...item, checked: false };
      });
      setDataCheckBox(newData);
      setListChecked([]);
    }
  };
  // END PLAN POST

  //START TIME POST
  const handleCheckTimePost = (e) => {
    const { value } = e.target;
    +value === 1 ? setIsCheckTimePost(true) : setIsCheckTimePost(false);
  };

  // END TIME_POST

  //START TIME Release
  const handleCheckTimeRelease = (e) => {
    const { value } = e.target;
    +value === 1 ? setIsCheckTimeRelease(true) : setIsCheckTimeRelease(false);
  };
  // END TIME RELEASE

  const handleShowPopupComfirmSuccess = (detailPost) => {
    setPopupLoading(false);
    setOpenProgress(false);
    if (typePost === TYPE_SHORT) {
      dispatch(resetIDHomePage());
      dispatch(resetDataInfoOther());
      navigate(`${newPathUser}`);
    } else {
      navigate(`${newPathUser}post/${detailPost?.id ? detailPost.id : postId}`);
    }
  };

  const handleShowPopupConfirmFail = () => {
    setPopupLoading(false);
    setOpenProgress(false);
  };

  const handleChoiceThumbnail = (e) => {
    let sizeImgBiggerTenMb = false;
    sizeImgBiggerTenMb = e.target.files[0].size > TEN_MB ? true : false;
    const checkTypes = acceptedImageTypes.includes(e.target.files[0]['type']);
    if (e.target.files[0] && checkTypes && !sizeImgBiggerTenMb) {
      const file = URL.createObjectURL(e.target.files[0]);
      setThumbnailUpload((prev) => ({ ...prev, img: e.target.files, files: file }));
    } else {
      sizeImgBiggerTenMb && failImgThan10Mb(e.target.files[0].size);
      !checkTypes && failImgInvalid(e.target.files[0].name);
    }
  };

  const handleInputThumbnail = (e) => {
    let sizeImgBiggerTenMb = false;
    sizeImgBiggerTenMb = e.target.files[0].size > TEN_MB ? true : false;
    const checkTypes = acceptedImageTypes.includes(e.target.files[0]['type']);
    if (e.target.files[0] && checkTypes && !sizeImgBiggerTenMb) {
      setThumbnailUpload((prev) => ({ ...prev, img: e.target.files }));
    } else {
      sizeImgBiggerTenMb && failImgThan10Mb(e.target.files[0].size);
      !checkTypes && failImgInvalid(e.target.files[0].name);
    }
  };

  const contentTypeNormal = () => {
    return (
      <>
        {videoUpload && (
          <div className='item-wrap mb-3'>
            <div className='item-label'>{t('Creator.PostArticle.labelThumbnail1')}</div>
            <div className={`main-thumbnail mt-1`}>
              {thumbnailUpload.img.length > 0 ? (
                <BlueImg url={`${decryptPath(thumbnailUpload.files)}`}>
                  <div className='bg-img bd-8'></div>
                  <ImgCustom src={thumbnailUpload.files} className='cover-img' />
                </BlueImg>
              ) : (
                <div className='upload-file-btn'>
                  <UploadImageIcon className='icon' />
                  <span className='text'>
                    {t('ValidateMsg.checkThumbnailUploadMultiLine')}
                  </span>
                </div>
              )}

              <input
                type='file'
                name='main_thumbnail'
                accept={acceptedImageTypes.map((item) => item).join(',')}
                onChange={handleInputThumbnail}
                onClick={(e) => (e.target.value = null)}
              />
              {thumbnailUpload.files && (
                <img
                  className='zindex20 btn-remove'
                  onClick={() => {
                    setThumbnailUpload({ img: [], files: null });
                  }}
                  src={BTN_REMOVE}
                  alt='remove'
                />
              )}
            </div>
          </div>
        )}
        <InputWrapper
          id='fsite-answer'
          label={t('Creator.PostArticle.canReply')}
          error={errors?.email ? errors?.email?.message : ''}
          className='form-item-switch'>
          <label className='switch'>
            <input type='checkbox' {...register('enable_comment')} />
            <span className='slider round'></span>
          </label>
        </InputWrapper>
        {checkPackage && (
          <InputWrapper
            id='fsite-category'
            className='box-category'
            label={t('Creator.PostArticle.selectPlan')}>
            <div className='box-checkbox scroll-bar-custom'>
              {dataCheckBox.length > 0 ? (
                <>
                  {/* check all */}
                  <Checkbox
                    register={{ ...register('all_package') }}
                    type='checkbox'
                    onChange={(e) => handleAllPlanPost(e)}
                    checked={listChecked?.length === dataCheckBox?.length}
                    text={t('Common.allPlan')}
                  />
                  {dataCheckBox.map((item, index) => (
                    <Controller
                      name={`package_ids[${index}]`}
                      control={control}
                      key={index}
                      render={() => {
                        return (
                          <Checkbox
                            id={item.id}
                            type='checkbox'
                            register={{ ...register(`package_ids[${index}]`) }}
                            onChange={(e) => handleOnchangePlanPost(e, item)}
                            text={item.title}
                            checked={item.checked}
                          />
                        );
                      }}
                    />
                  ))}
                </>
              ) : (
                <span style={{ color: '#ff0000' }}>{t('ValidateMsg.anySubscription')}</span>
              )}
            </div>
          </InputWrapper>
        )}

        <InputWrapper className='box-category'>
          <Checkbox
            type='checkbox'
            name={`in_24h_flag`}
            onChange={(e) => setCheckPostIn24h(e.target.checked)}
            text={t('Creator.PostArticle.postIn24h')}
            value={1}
          />
        </InputWrapper>
        {isHaveImgOrVideo && (
          <div className='performer-kyc'>
            <InputWrapper
              label={t('Creator.PostArticle.postContent.PerformerInformation')}
              required></InputWrapper>
            {listPerformer?.length > 0 && (
              <div className='list-performer'>
                {listPerformer?.map((item) => {
                  return (
                    <div
                      key={item?.id}
                      id={item?.id}
                      className='item-performer'>{`${item.last_name_romaji} ${item.first_name_romaji}`}</div>
                  );
                })}
              </div>
            )}
            <div className='btn-performer' onClick={() => setIsShowPerformerPopup(true)}>
              <PerformerIcon className='performer-icon' />
              {t('Creator.PostArticle.postContent.PerformerInformation')}
            </div>
          </div>
        )}
        <InputWrapper
          id='fsite-type-sell'
          label={t('Creator.PostArticle.settingTimePost')}
          className='box-type-sell'>
          <div className={`box-radio-checkbox post`}>
            <Checkbox
              type='radio'
              checked={!isCheckTimePost}
              value={0}
              register={{ ...register('check_time_post') }}
              onChange={handleCheckTimePost}
              text={t('Creator.PostArticle.notSpecified')}
            />
            <Checkbox
              type='radio'
              register={{ ...register('check_time_post') }}
              onChange={handleCheckTimePost}
              text={t('Creator.PostArticle.specify')}
              checked={isCheckTimePost}
              value={1}
            />
          </div>
        </InputWrapper>
        <div
          className='d-flex date-time-wrapper position-relative'
          style={{
            marginBottom: isCheckTimePost && (!date1 || (date1 && !timePostCheck)) ? 16 : 0,
          }}>
          <InputWrapper id='fsite-start-time' className='box-date'>
            <div className='date'>
              {/* <CalendarIconV2
                onClick={() => {
                  if (isVisibleCalendar2) {
                    toggleCalendar2();
                  }
                  if (isCheckTimePost) {
                    toggleCalendar1();
                  }
                }}
                color={isVisibleCalendar1 && '#DC143C'}
              /> */}
              <input
                placeholder='YYYY/MM/DD'
                value={date1}
                readOnly
                onClick={() => {
                  if (isVisibleCalendar2) {
                    toggleCalendar2();
                  }
                  toggleCalendar1();
                }}
                disabled={!isCheckTimePost}
              />
              {isVisibleCalendar1 && isCheckTimePost && (
                <ReactCalendar
                  customPosition='top-left'
                  minDate={new Date()}
                  onChange={(e) => {
                    onChangeDate1(e);
                  }}
                  //inputRef={datePickerRef1}
                />
              )}
            </div>
          </InputWrapper>

          <InputWrapper id='fsite-birthday' className='box-time'>
            <CustomSelectTime
              placeholder='--:--'
              handleOnClick={() => {
                if (isVisibleCalendar1) {
                  toggleCalendar1();
                }
                if (isVisibleCalendar2) {
                  toggleCalendar2();
                }
              }}
              timePost={(e) => {
                setTimePost(e);
                setTimePostCheck(e);
              }}
              checkDisabled={isCheckTimePost}
              showIconV2
              colorDefaultIcon='#BEBEBE'
            />
          </InputWrapper>
          {isCheckTimePost && !date1 && validateTimePostNormalRequired()}
          {isCheckTimePost &&
            date1 &&
            !timePostCheck &&
            validateTimePostNormalRequired({
              right: '0px',
            })}
        </div>
        <InputWrapper
          id='fsite-type-sell'
          label={t('Creator.PostArticle.settingTimePublish')}
          className='box-type-sell'>
          <div
            className={`box-radio-checkbox time-release ${checkPostIn24h && 'in-24h'} post`}>
            <Checkbox
              type='radio'
              register={{ ...register('check_time_release') }}
              onChange={handleCheckTimeRelease}
              text={t('Creator.PostArticle.notSet')}
              checked={!isCheckTimeRelease}
              value={0}
              disabled={checkPostIn24h}
            />
            <Checkbox
              type='radio'
              register={{ ...register('check_time_release') }}
              onChange={handleCheckTimeRelease}
              text={t('Creator.PostArticle.specify')}
              checked={isCheckTimeRelease}
              value={1}
              disabled={checkPostIn24h}
            />
          </div>
        </InputWrapper>
        <div
          className='d-flex date-time-wrapper position-relative'
          style={{
            marginBottom:
              !checkPostIn24h && isCheckTimeRelease && (!date2 || (date2 && !timeReleaseCheck))
                ? 32
                : 0,
          }}>
          <InputWrapper id='fsite-start-time' className='box-date'>
            <div className='date'>
              <CalendarIconV2
                onClick={() => {
                  if (isVisibleCalendar1) {
                    toggleCalendar1();
                  }
                  if (!(!isCheckTimeRelease || checkPostIn24h)) {
                    toggleCalendar2();
                  }
                }}
                color={isVisibleCalendar2 && '#DC143C'}
              />
              <input
                placeholder='YYYY/MM/DD'
                value={date2}
                readOnly
                onClick={() => {
                  if (isVisibleCalendar1) {
                    toggleCalendar1();
                  }
                  toggleCalendar2();
                }}
                disabled={!isCheckTimeRelease || checkPostIn24h}
              />
              {isVisibleCalendar2 && isCheckTimeRelease && (
                <ReactCalendar
                  customPosition='top-left'
                  minDate={new Date()}
                  onChange={(e) => {
                    onChangeDate2(e);
                  }}
                  inputRef={datePickerRef2}
                />
              )}
            </div>
          </InputWrapper>

          <InputWrapper id='fsite-birthday' className='box-time'>
            <CustomSelectTime
              placeholder='--:--'
              scrollToBottom={true}
              handleOnClick={() => {
                if (isVisibleCalendar1) {
                  toggleCalendar1();
                }
                if (isVisibleCalendar2) {
                  toggleCalendar2();
                }
              }}
              timePost={(e) => {
                setTimeRelease(e);
                setTimeReleaseCheck(e);
              }}
              checkDisabled={!checkPostIn24h && isCheckTimeRelease}
              classNameCustom='top'
              showIconV2
              colorDefaultIcon='#BEBEBE'
            />
          </InputWrapper>
          {!checkPostIn24h && isCheckTimeRelease && !date2 && validateTimePostNormalRequired()}
          {!checkPostIn24h &&
            isCheckTimeRelease &&
            date2 &&
            !timeReleaseCheck &&
            validateTimePostNormalRequired({
              right: '0px',
            })}
        </div>
        {/* <div className='see-next' onClick={() => setSeeNext(false)}>
          {t('Creator.PostArticle.hideSeeNext')}
          <UpOutlined />
        </div> */}
      </>
    );
  };

  const contentTypeShort = () => {
    return (
      <>
        {videoUpload && (
          <div className='item-wrap mb-3'>
            <div className='item-label'>{t('Creator.PostArticle.labelThumbnail1')}</div>
            <div className={`main-thumbnail mt-1`}>
              {thumbnailUpload.img.length > 0 ? (
                <BlueImg url={`${decryptPath(thumbnailUpload.files)}`}>
                  <div className='bg-img bd-8'></div>
                  <ImgCustom src={thumbnailUpload.files} className='cover-img' />
                </BlueImg>
              ) : (
                <div className='upload-file-btn'>
                  <UploadImageIcon className='icon' />
                  <span className='text'>
                    {t('ValidateMsg.checkThumbnailUploadMultiLine')}
                  </span>
                </div>
              )}

              <input
                type='file'
                name='main_thumbnail'
                accept={acceptedImageTypes.map((item) => item).join(',')}
                onChange={handleInputThumbnail}
                onClick={(e) => (e.target.value = null)}
              />
              {thumbnailUpload.files && (
                <img
                  className='zindex20 btn-remove'
                  onClick={() => {
                    setThumbnailUpload({ img: [], files: null });
                  }}
                  src={BTN_REMOVE}
                  alt='remove'
                />
              )}
            </div>
          </div>
        )}
        {/* <InputWrapper
          id='fsite-answer'
          label={t('Creator.PostArticle.postShort.buttonHighlight')}
          error={errors?.email ? errors?.email?.message : ''}
          className='form-item-switch'>
          <label className='switch'>
            <input
              type='checkbox'
              value={showThumbnail}
              onClick={(e) => {
                setShowThumbnail(e.target.checked);
              }}
            />
            <span className='slider round'></span>
          </label>
        </InputWrapper>

        {showThumbnail && (
          <div className='fansite-creator-post-normal__thumbnail'>
            <div className='layer'>
              {thumbnailUpload.img.length > 0 ? (
                <BlueImg url={`${thumbnailUpload.files?.replaceAll(')', '\\)')}`}>
                  <div className='bg-img'></div>
                  <ImgCustom src={thumbnailUpload.files} className='img_thumbnail cover-img' />
                </BlueImg>
              ) : (
                <div className='add-icon'>
                  <UploadImageIcon className='upload-icon' />
                  <span className='text'>{t('Creator.PostArticle.choiceThumbnail')}</span>
                </div>
              )}
              {thumbnailUpload.files && (
                <CloseIconV2
                  onClick={() => {
                    setThumbnailUpload({ img: [], files: null });
                  }}
                />
              )}
              <button className='btn'>{t('Creator.PostArticle.uploadImage')}</button>
              <input
                type='file'
                name='list_img'
                accept={acceptedImageTypes.map((item) => item).join(',')}
                className='add-multi'
                multiple
                onChange={handleChoiceThumbnail}
                onClick={(e) => (e.target.value = null)}
              />
            </div>
          </div>
        )} */}

        <InputWrapper
          id='fsite-answer'
          label={t('Creator.PostArticle.canReply')}
          error={errors?.email ? errors?.email?.message : ''}
          className='form-item-switch'>
          <label className='switch'>
            <input type='checkbox' {...register('enable_comment')} />
            <span className='slider round'></span>
          </label>
        </InputWrapper>

        <InputWrapper className='box-category'>
          <Checkbox
            type='checkbox'
            name={`in_24h_flag`}
            onChange={(e) => setCheckPostIn24h(e.target.checked)}
            text={t('Creator.PostArticle.postIn24h')}
            value={1}
          />
        </InputWrapper>
        {isHaveImgOrVideo && (
          <div className='performer-kyc'>
            <InputWrapper
              label={t('Creator.PostArticle.postContent.PerformerInformation')}
              required></InputWrapper>
            {listPerformer?.length > 0 && (
              <div className='list-performer'>
                {listPerformer?.map((item) => {
                  return (
                    <div
                      key={item?.id}
                      id={item?.id}
                      className='item-performer'>{`${item.last_name_romaji} ${item.first_name_romaji}`}</div>
                  );
                })}
              </div>
            )}
            <div className='btn-performer' onClick={() => setIsShowPerformerPopup(true)}>
              <PerformerIcon className='performer-icon' />
              {t('Creator.PostArticle.postContent.PerformerInformation')}
            </div>
          </div>
        )}
        {/* <div className='see-next' onClick={() => setSeeNext(false)}>
          {t('Creator.PostArticle.hideSeeNext')}
          <UpOutlined />
        </div> */}
      </>
    );
  };

  const contentType = () => {
    if (typePost === TYPE_NORMAL) {
      return contentTypeNormal();
    }
    return contentTypeShort();
  };

  const checkPackage = useMemo(() => {
    return watchFollowFlag?.value === '0' && (imgUpload?.length > 0 || videoUpload);
  }, [watchFollowFlag?.value, imgUpload, videoUpload]);

  useEffect(() => {
    if ((!imgUpload || imgUpload?.length === 0) && !videoUpload) {
      setValue('follow_flag', optionSend[0]);
    }
  }, [imgUpload, videoUpload]);

  const upLoadMainVideo = (dataCreate, main_video, thumbnail) => {
    if (typePost === TYPE_SHORT) {
      const newData = {
        ...dataCreate,
        duration: +durationVideo * 1000,
        thumbnail: main_video && thumbnail,
        short_video: main_video,
      };
      dispatch(
        creatorPostArticle(
          newData,
          typePost === TYPE_NORMAL ? 1 : 4,
          handleShowPopupComfirmSuccess,
          () => handleShowPopupConfirmFail()
        )
      );
    } else if (!imgUpload?.length || imgUpload?.length <= 0) {
      let newData = {
        ...dataCreate,
        thumbnail: main_video && thumbnail,
        duration: +durationVideo * 1000,
        main_video,
      };
      dispatch(
        creatorPostArticle(
          newData,
          typePost === TYPE_NORMAL ? 1 : 4,
          handleShowPopupComfirmSuccess,
          () => handleShowPopupConfirmFail()
        )
      );
    }
  };

  //ONSUBMIT
  const onSubmit = async (data) => {
    let dataCreate = {};
    if (isApproved) {
      let newData = {
        ...data,
        public_time: isCheckTimePost && currentTimeToUTC(new Date(`${date1} ${timePost}`)),
        unpublic_time:
          isCheckTimeRelease && currentTimeToUTC(new Date(`${date2} ${timeRelease}`)),
        in_24h_flag: checkPostIn24h ? '1' : '0',
        follow_flag: data?.follow_flag?.value || 0,
        highlight_flag: showThumbnail ? 1 : 0,
      };
      if (checkPackage) {
        newData = {
          ...newData,
          package_ids: listChecked,
          all_package:
            creatorListPackage.length > 0
              ? dataCheckBox.length === listChecked.length
                ? 1
                : 0
              : 0,
        };
      } else {
        newData = {
          ...newData,
          all_package: 0,
          package_ids: [],
        };
      }
      dataCreate = {
        ...datagetPresignedUrlS3,
        ...newData,
        contract_ids: isHaveImgOrVideo ? listPerformer?.map((e) => e.id) : [],
      };
      setDatagetPresignedUrlS3((prev) => ({ ...prev, ...newData }));
    } else {
      setDatagetPresignedUrlS3({
        ...data,
        follow_flag: data?.follow_flag?.value || 0,
        enable_comment: data?.enable_comment ? 1 : 0,
      });
      dataCreate = {
        ...datagetPresignedUrlS3,
        ...data,
        follow_flag: data?.follow_flag?.value || 0,
        enable_comment: data?.enable_comment ? 1 : 0,
      };
    }
    handleConfirmSubmit(dataCreate);
  };

  const pcVideo = useMemo(() => {
    let percent = 0;
    if (videoUpload) {
      if (percentUpload?.length) {
        percentUpload.forEach((element) => {
          percent += Number(element);
        });
        percent /= percentUpload?.length;
      }
    }
    return Math.floor(percent);
  }, [percentUpload, videoUpload]);

  const pcThumbnail = useMemo(() => {
    let percent = 0;
    if (thumbnailUpload && thumbnailUpload?.img[0]) {
      if (percentThumbnail?.length) {
        percentThumbnail.forEach((element) => {
          percent += Number(element);
        });
        percent /= percentThumbnail?.length;
      }
    }
    return Math.floor(percent);
  }, [percentThumbnail, thumbnailUpload]);

  const pcImg = useMemo(() => {
    let percent = 0;
    if (imgUpload?.length) {
      if (percentImg?.length) {
        percentImg.forEach((element) => {
          percent += Number(element);
        });
        percent /= percentImg?.length;
      }
    }
    return Math.floor(percent);
  }, [imgUpload, percentImg]);

  const countItemUpload = useMemo(() => {
    const countImg = imgUpload?.length ? 1 : 0;
    const countVideo = videoUpload ? 1 : 0;
    const countThumbnail = thumbnailUpload && thumbnailUpload?.img[0] ? 1 : 0;
    return countImg + countVideo + countThumbnail;
  }, [videoUpload, imgUpload, thumbnailUpload]);

  const pcUpload = useMemo(() => {
    return Math.floor((pcVideo + pcImg + pcThumbnail) / countItemUpload);
  }, [pcImg, pcVideo, pcThumbnail, countItemUpload]);

  const handleConfirmSubmit = async (dataCreate) => {
    if (isApproved && countItemUpload > 0) {
      setOpenProgress(true);
    } else {
      setPopupLoading(true);
    }
    let listLinkS3Img = [];
    let listLinkPreSignedImg = [];
    let main_video_pre_signed;
    let main_video = null;
    let thumbnail;
    if (isApproved) {
      if (thumbnailUpload?.files) {
        await getPresignedUrlS3(
          'image',
          `uploads/output/images`,
          setFileNameS3(thumbnailUpload?.img[0]?.name),
          'post'
        ).then(async (resUrl) => {
          thumbnail = resUrl.data.file_path;
          setThumbnail(resUrl.data.file_path);
          await uploadS3ByPresigned(
            resUrl.data.pre_signed,
            thumbnailUpload?.img[0],
            setPercentThumbnail,
            percentThumbnail,
            0
          );
        });
      }
      if (typeof videoUpload === 'string') {
        main_video = videoUpload;
        if (!imgUpload?.length || imgUpload?.length <= 0) {
          let newData = {
            ...dataCreate,
            thumbnail: main_video && thumbnail,
            main_video,
          };
          dispatch(
            creatorPostArticle(
              newData,
              typePost === TYPE_NORMAL ? 1 : 4,
              handleShowPopupComfirmSuccess,
              () => handleShowPopupConfirmFail()
            )
          );
        }
      } else if (videoUpload) {
        if (videoUpload?.size > CHUNK_FILE) {
          try {
            const resUrl = await uploadVideoWithMulti(videoUpload, setPercentUpload);
            main_video = resUrl.data.file_path;
            upLoadMainVideo(dataCreate, main_video);
          } catch (error) {
            handleShowPopupConfirmFail();
          }
        } else {
          await getPresignedUrlS3(
            'video',
            `uploads/output/videos`,
            setFileNameS3(videoUpload.name),
            'post'
          )
            .then((resUrl) => {
              main_video_pre_signed = resUrl.data.pre_signed;
              main_video = resUrl.data.file_path;
            })
            .then(async () => {
              await uploadS3ByPresigned(
                main_video_pre_signed,
                videoUpload,
                setPercentUpload,
                percentUpload,
                0
              )
                .then(() => {
                  upLoadMainVideo(dataCreate, main_video, thumbnail);
                })
                .catch(() => handleShowPopupConfirmFail());
            })
            .catch((_error) => handleShowPopupConfirmFail());
        }
      }
      if (imgUpload?.length > 0) {
        const promiseList = imgUpload.map((item) => {
          return getPresignedUrlS3(
            'image',
            `uploads/output/images`,
            setFileNameS3(item.name),
            'post'
          );
        });
        Promise.all(promiseList)
          .then((responses) => {
            responses.forEach((element) => {
              if (element.success) {
                listLinkS3Img.push(element.data.file_path);
                listLinkPreSignedImg.push(element.data.pre_signed);
              }
            });
            const promiseGenerate = listLinkPreSignedImg.map((m, i) => {
              return uploadS3ByPresigned(m, imgUpload[i], setPercentImg, percentImg, i);
            });
            Promise.all(promiseGenerate).then(() => {
              let newData = {
                ...dataCreate,
                thumbnail: main_video && thumbnail,
                duration: +durationVideo * 1000,
                main_video,
              };
              if (typePost === TYPE_SHORT) {
                newData = { ...newData, short_images: listLinkS3Img };
              } else {
                newData = { ...newData, images: listLinkS3Img };
              }
              dispatch(
                creatorPostArticle(
                  newData,
                  typePost === TYPE_NORMAL ? 1 : 4,
                  handleShowPopupComfirmSuccess,
                  () => handleShowPopupConfirmFail()
                )
              );
            });
          })
          .catch((_errors) => handleShowPopupConfirmFail());
      }
      !videoUpload &&
        !imgUpload.length > 0 &&
        !recording?.length > 0 &&
        dispatch(
          creatorPostArticle(
            dataCreate,
            typePost === TYPE_NORMAL ? 1 : 4,
            handleShowPopupComfirmSuccess,
            () => handleShowPopupConfirmFail()
          )
        );
    } else {
      dispatch(
        creatorPostNotApprovedArticle(
          { ...dataCreate, type: typePost === TYPE_NORMAL ? 1 : 4 },
          (data) => {
            if (data?.id) {
              setPostId(data.id);
            }
            handleShowPopupComfirmSuccess(data);
          },
          () => {
            setPostId(null);
            setPopupLoading(false);
            setOpenProgress(false);
          }
        )
      );
    }
  };

  const isTabSale = useMemo(() => {
    if (location?.pathname) {
      return location?.pathname?.split('/').includes('post-content');
    }
    return false;
  }, [location?.pathname]);

  const [idPostMeta, setIdPostMeta] = useState(null);
  const [postDetailMeta, setPostDetailMeta] = useState(null);

  const contentValue = watch('content', '');
  useEffect(() => {
    const isValidContent = contentValue?.trim() !== '';
    if (isValidContent) {
      const idPost = checkLinkPostSale(contentValue);
      if (idPost) {
        setIdPostMeta(idPost);
      } else {
        setIdPostMeta(null);
        setPostDetailMeta(null);
      }
    } else {
      setPostDetailMeta(null);
      setIdPostMeta(null);
    }
  }, [contentValue]);

  useEffect(() => {
    if (idPostMeta) {
      dispatch(
        getInfoMetaPost(idPostMeta, (dataMeta) => {
          setPostDetailMeta(dataMeta);
        })
      );
    } else {
      setPostDetailMeta(null);
    }
  }, [idPostMeta, dispatch]);

  return (
    <>
      {openProgress && (
        <div className='layer-spinner'>
          <ProcessingStatusBar percentage={pcUpload} />
        </div>
      )}
      {popupLoading && (
        <div className='layer-spinner'>
          <LoadingIconV2 className='spinner-icon' />
        </div>
      )}
      {isShowPopupConfirm && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsShowPopupConfirm(e)}
          colorSvg='#FF9F43'
          iconNote={true}
          text={t('Creator.Message.featureNotAvailableSale')}
          className={'popup-confirm__with-draw'}>
          <div className='btn confirm' onClick={() => {
            navigate(`/profile/info`, {
              state: {
                redirectBlockRequest: true,
              },
            });
          }}>
          {t('Common.update')}
          </div>
          <div className='btn cancel' onClick={() => setIsShowPopupConfirm(false)}>
            {t('WithDraw.cancel')}
          </div>
        </PopupConfirmFan>
      )}
      {isShowPerformerPopup && (
        <PerformerPopup
          listPerformer={listPerformer}
          setListPerformer={setListPerformer}
          title={t('Contract.createContract')}
          handleClosePopup={() => setIsShowPerformerPopup(false)}
        />
      )}
      {isVisiblePopUpConfirmWarningApproved && (
        <PopupConfirmFan
          isVisiblePopup={() => setIsVisiblePopUpConfirmWarningApproved(false)}
          colorSvg='#FF9F43'
          iconNote={true}
          text={t('Popup.confirmApproved')}>
          <div
            className='ok btn'
            onClick={() => setIsVisiblePopUpConfirmWarningApproved(false)}>
            {t('Common.Ok')}
          </div>
        </PopupConfirmFan>
      )}
      <LayoutCreatorRight
        titlePage={t('Common.post')}
        className='layout-post-normal'
        noButtonBack>
        <div className={`fansite-creator-post-normal ${seeNext ? 'next' : ''}`}>
          <div className='tab-container'>
            {listTab.map((v, i) => (
              <div
                key={v.id}
                className={`item ${v?.isTabSale === isTabSale ? ` active ` : ``}`}
                style={{ pointerEvents: `${(loading || loadingListPackage) ? 'none' : 'auto'}`}}
                onClick={() => {
                  if (v?.isTabSale !== isTabSale) {
                    if (dataProfile?.status !== 1) {
                      setIsShowPopupConfirm(true)
                    } else {
                      navigate(`${newPathUser}${v.path}`);
                    }
                  }
                }}>
                {t(v.label)}
              </div>
            ))}
          </div>
          {loading || loadingListPackage ? (
            <div style={{ paddingTop: `${isMobile ? '16px' : '24px'}` }}>
              <ShimmerPostDetails className='shimmer-loading' />
            </div>
          ) : (
            <form onSubmit={(e) => e.preventDefault()} className='fansite-form'>
              <InputWrapper
                id='fsite-content'
                label=''
                required={true}
                error={errors?.content ? errors?.content?.message : ''}
                className={`${imgUpload && 'has-img'} text-post ${
                  postDetailMeta?.title ? 'have-meta' : ''
                }`}>
                <div
                  className={`wrapper-content-ogp ${
                    postDetailMeta?.title ? 'have-meta' : ''
                  }`}>
                  <textarea
                    {...register('content', {
                      onChange: (e) => handleTextarea(e),
                    })}
                    placeholder={t('Creator.PostArticle.placeholderContent')}
                    onKeyDown={(e) => {
                      const newContent = e.target.value;
                      const trimmedContent = newContent.trim();
                      if (newContent?.length > 500) {
                        setError('content', {
                          type: 'maxLength',
                          message: t('ValidateMsg.CONTENT_IS_TOO_LONG'),
                        });
                      } else if (!isApproved && !patternNoFirstSpacing.test(trimmedContent)) {
                        setError('content', {
                          type: 'firstSpace',
                          message: t('ValidateMsg.contentRequired'),
                        });
                      } else if (!isApproved && trimmedContent === '') {
                        setError('content', {
                          type: 'required',
                          message: t('ValidateMsg.itemRequired'),
                        });
                      } else {
                        clearErrors('content');
                      }

                      handleTextarea(e);
                      return newContent;
                    }}
                    className='content-ogp'
                    maxLength={500}
                    style={{ border: 'none' }}
                  />
                  {postDetailMeta?.title && (
                    <MetaTagViewer
                      title={postDetailMeta?.title}
                      url={postDetailMeta?.url}
                      thumbnail={postDetailMeta?.thumbnail}
                    />
                  )}
                </div>
              </InputWrapper>

              {isApproved && (
                <>
                  <ListImgVideo
                    main_video={handleInputVideo}
                    list_img={handleListImage}
                    thumbnail={thumbnail}
                    imgRecording={imgRecording}
                    setTypePost={setTypePost}
                    typePost={typePost}
                    setDurationVideo={setDurationVideo}
                  />
                  {isShowErrorPackages && (
                    <div className='text-err'>
                      {t('Creator.PostArticle.postShort.noImgWhenOnlyFollow')}
                    </div>
                  )}
                  {/* {validImgFirstTime && !watchContent && !isHaveImgOrVideo && (
                    <div className='text-err'>
                      {t('Creator.PostArticle.postShort.noImgWhenOnlyFollow')}
                    </div>
                  )} */}
                </>
              )}
              <InputWrapper label={t('Creator.PostArticle.postShort.whoSeePost')}>
                <Controller
                  name='follow_flag'
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={optionSend}
                      value={optionSend?.find((e) => e?.value === field?.value?.value)}
                      onChange={(val) => {
                        field.onChange(val);
                      }}
                      noOptionsMessage={() => {
                        t('Common.listNull');
                      }}
                      className='select'
                      isDisabled={dataProfile?.status !== 1}
                      defaultValue={optionSend[0]}
                      isSearchable={false}
                      styles={customStylesReselect}
                      components={{
                        Option: CustomOption,
                        DropdownIndicator: CustomDropdownIndicator,
                      }}
                    />
                  )}
                />
              </InputWrapper>
              {!isApproved && (
                <InputWrapper
                  id='fsite-answer'
                  label={t('Creator.PostArticle.canReply')}
                  error={errors?.email ? errors?.email?.message : ''}
                  className='form-item-switch'>
                  <label className='switch'>
                    <input type='checkbox' {...register('enable_comment')} />
                    <span className='slider round'></span>
                  </label>
                </InputWrapper>
              )}
              {isApproved && (
                <>
                  <div
                    className={`see-next ${seeNext ? 'next' : ''}`}
                    onClick={() => setSeeNext(!seeNext)}>
                    {t('Creator.PostArticle.seeNext')}
                    {seeNext ? <UpOutlined /> : <DownOutlined />}
                  </div>
                  {seeNext && contentType()}
                </>
              )}

              <InputWrapper id='fsite-submit' className={`button-submit`}>
                <div className='fansite-creator-post-normal__footer'>
                  <button
                    onClick={handleSubmit(onSubmit)}
                    className='btn btn-submit'
                    disabled={disabledButton}>
                    {t('Common.post')}
                  </button>
                  <button onClick={() => navigate(-1)} className='btn btn-cancel'>
                    {t('Common.buttonCancel')}
                  </button>
                </div>
              </InputWrapper>
            </form>
          )}
        </div>
      </LayoutCreatorRight>
    </>
  );
};

export default withCreatorRole(PostNormal);
