import { ImgCustom, VideoDetail } from 'components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'video-react/dist/video-react.css';
import {
  acceptedImageTypes,
  acceptedImgVideoTypes,
  failImgInvalid,
  failImgThan10,
  failImgThan10Mb,
  failVideoInvalid,
  failVideoThan1,
  TEN_GB,
  TEN_MB,
  linkS3Video,
  failShortVideo,
  getDurationVideo,
  TYPE_VIDEOS,
  failMoreThan1Video,
  decryptPath,
  failFileThan10Gb,
} from '../../../../../utils/utils';
import '../normal.scss';
import VideoCustomDetail from 'pages/creator/layout/recording/VideoCustomDetail';
import UploadImageIconV2 from 'images/UploadImageIconV2';
import UploadVideoIconV2 from 'images/UploadVideoIconV2';
import UploadCameraIconV2 from 'images/UploadCameraIconV2';
import { pushNotify } from 'components/Notification';
import { useDispatch } from 'react-redux';
import { postDurationVideo } from 'store/actions/getDurationVideo';
import { TYPE_NORMAL, TYPE_SHORT } from '.';
import CloseIconV2 from 'images/CloseIconV2';
import { useRef } from 'react';
import { useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { getInfo } from 'react-mediainfo';

const ListImgVideo = ({
  main_video,
  list_img,
  imgRecording,
  thumbnail,
  setTypePost,
  setDurationVideo,
  typePost,
}) => {
  const dispatch = useDispatch();
  const [videoUpload, setVideoUpload] = useState({ img: [], files: null });
  const [imgUpload, setImgUpload] = useState({ img: [], files: [] });
  const { t } = useTranslation();
  // START IMG VIDEO

  const refUploadVideo = useRef(null);
  const refUploadImage = useRef(null);
  const refUploadCamera = useRef(null);

  const lengthImages = useMemo(() => {
    if (videoUpload?.img?.length > 0) {
      return 9;
    }
    return 10;
  }, [videoUpload?.img]);
  useEffect(() => {
    if (imgRecording) {
      setVideoUpload((prev) => ({ ...prev, img: [imgRecording], typeLive: true }));
    }
  }, [imgRecording]);

  useEffect(() => {
    const { img } = imgUpload;
    if (img.length > 0) {
      const newArrImg = [];
      img.forEach((ele) => {
        newArrImg.push(URL.createObjectURL(ele));
      });
      setImgUpload((prev) => ({ ...prev, files: newArrImg }));
      list_img(img);
    } else {
      list_img([]);
    }

    if (videoUpload.img.length > 0) {
      if (!videoUpload?.typeLive) {
        const file = URL.createObjectURL(videoUpload.img[0]);
        setVideoUpload((prev) => ({ ...prev, files: file }));
      }

      main_video(videoUpload.img[0]);
    } else {
      main_video(null);
    }
  }, [videoUpload.img, imgUpload.img]);

  const inputImage = () => {
    const isDisable =
      videoUpload.img.length + imgUpload.img.length >= 10 ||
      (videoUpload.img.length > 0 && typePost === TYPE_SHORT) ||
      (imgUpload.img.length > 0 && typePost === TYPE_SHORT);
    return (
      <div
        className={`inputFile ${isDisable && 'disableImgInput'}`}
        onClick={() => refUploadImage?.current?.click()}>
        <div className='btn-upload'>
          <UploadImageIconV2 />
          <span>{t('Creator.PostArticle.imageUpload')}</span>
        </div>
        <input
          ref={refUploadImage}
          style={{ display: 'none' }}
          type='file'
          multiple='multiple'
          accept={acceptedImgVideoTypes.map((item) => item).join(',')}
          onChange={handleInputImg}
          onClick={(e) => (e.target.value = null)}
          disabled={isDisable}
        />
      </div>
    );
  };

  const handleInputImg = async (e) => {
    let sizeImgBiggerTenMb = false;
    let sizeVideoBiggerFiveGB = false;
    let checkImg = true;
    let lengthVideoUpload = 0;
    let checkVideos = true;
    let listImages = [];
    let video = null;
    const checkLength = imgUpload.img.length + e.target.files.length;
    for (var i = 0; i < e.target.files.length; i++) {
      if (acceptedImageTypes.includes(e.target.files[i]['type'])) {
        if (e.target.files[i].size > TEN_MB) {
          sizeImgBiggerTenMb = true;
        }
        listImages.push(e.target.files[i]);
      } else if (TYPE_VIDEOS.includes(e.target.files[i]['type'])) {
        video = e.target.files[i];
        if (e.target.files[i].size > TEN_GB) {
          sizeVideoBiggerFiveGB = true;
        }
        lengthVideoUpload += 1;
      } else {
        checkImg = false;
      }
    }
    if (lengthVideoUpload > 1 || (videoUpload.img.length > 0 && lengthVideoUpload > 0)) {
      checkVideos = false;
    }
    if (
      e.target.files[0] &&
      checkImg &&
      !sizeImgBiggerTenMb &&
      !sizeVideoBiggerFiveGB &&
      checkLength <= lengthImages &&
      checkVideos
    ) {
      setImgUpload((prev) => ({ ...prev, img: [...imgUpload.img, ...listImages] }));
      if (video) {
        const mediaInfoLib = await getInfo(e.target.files[0]);
        const durationVideo = +mediaInfoLib?.media?.track[0]?.Duration;
        setVideoUpload({ files: URL.createObjectURL(video), img: [video] });
        setDurationVideo(durationVideo);
      }
      setTypePost(TYPE_NORMAL);
    } else {
      !checkVideos && failMoreThan1Video();
      sizeImgBiggerTenMb && failImgThan10Mb();
      sizeVideoBiggerFiveGB && failFileThan10Gb();
      checkLength > lengthImages && failImgThan10();
      !checkImg && failImgInvalid();
    }
  };

  const handleRemoveImg = (i) => {
    const { img } = imgUpload;
    const removeEle = img.splice(i, 1);
    img.length === 0 && setImgUpload({ img: [], files: [] });
    setImgUpload((prev) => ({
      ...prev,
      img: img.filter((item) => item !== removeEle[0]),
    }));
  };

  const inputVideo = () => {
    const isDisable =
      videoUpload.img.length > 0 ||
      imgUpload.img.length > 10 ||
      (imgUpload.img.length > 0 && typePost === TYPE_NORMAL) ||
      imgRecording?.length > 0;
    return (
      <div
        className={`inputFile ${isDisable && 'disableVideoInput'}`}
        onClick={() => refUploadVideo?.current?.click()}>
        <div className='btn-upload'>
          <UploadVideoIconV2 />
          <span>{t('Creator.PostArticle.videoUpload')}</span>
        </div>
        <input
          ref={refUploadVideo}
          style={{ display: 'none' }}
          type='file'
          multiple='multiple'
          accept={acceptedImgVideoTypes.map((item) => item).join(',')}
          onChange={handleInputVideo}
          onClick={(e) => (e.target.value = null)}
          disabled={isDisable}
        />
      </div>
    );
  };

  const handleInputVideo = async (e) => {
    let videoSelect = false;
    let imageSelect = false;
    const targetFiles = e.target.files;
    let targetFilesObject = [...targetFiles];
    if (
      targetFilesObject.length + imgUpload.length > 10 ||
      targetFilesObject.length + videoUpload.length > 10
    ) {
      return pushNotify('error', `${t('ValidateMsg.uploadThan10Img')}`);
    }
    /**
     * check img vs video
     */
    targetFilesObject.map((file) => {
      const isImage = file.type.includes('image');
      const isVideo = file.type.includes('video');
      const maxSize = isImage ? TEN_MB : TEN_GB;
      const fileType = isImage ? acceptedImageTypes : TYPE_VIDEOS;
      if (+file.size > +maxSize) {
        return pushNotify(
          'error',
          isImage ? `${t('ValidateMsg.imgThan10Mb1')}` : `${t('ValidateMsg.videoThan1Gb1')}`
        );
      } else if (isImage && !fileType.includes(file.type)) {
        return pushNotify('error', `${t('ValidateMsg.typeImg1')}`);
      } else if (isVideo && !fileType.includes(file.type)) {
        return pushNotify('error', `${t('ValidateMsg.typeVideo1')}`);
      } else if (!isVideo && !isImage) {
        pushNotify('error', `${t('ValidateMsg.typeImg1')}`);
        return pushNotify('error', `${t('ValidateMsg.typeVideo1')}`);
      } else if (
        (imgUpload.img.length > 0 && isVideo) ||
        (videoUpload.img.length > 0 && isImage)
      ) {
        return pushNotify('error', `${t('ValidateMsg.ImgAndVideoRequired')}`);
      } else if (isImage) {
        return (imageSelect = true);
      } else {
        return (videoSelect = true);
      }
    });

    const checkLengthVideo = videoUpload.img.length + e.target.files.length;
    if (videoSelect === true && (checkLengthVideo > 1 || e.target.files.length > 1)) {
      return pushNotify('error', `${t('ValidateMsg.uploadThan1Video')}`);
    }

    if (imageSelect && videoSelect) {
      return pushNotify('error', `${t('ValidateMsg.ImgAndVideoRequired')}`);
    } else if (imageSelect && !videoSelect) {
      let sizeImgBiggerTenMb = false;
      let checkImg = true;
      const checkLength = imgUpload.img.length + e.target.files.length;
      const newArrImg = [];
      for (var i = 0; i < e.target.files.length; i++) {
        if (e.target.files[i].size > TEN_MB) sizeImgBiggerTenMb = true;
        if (!acceptedImageTypes.includes(e.target.files[i]['type'])) checkImg = false;
        newArrImg.push(URL.createObjectURL(e.target.files[i]));
      }
      if (e.target.files[0] && checkImg && !sizeImgBiggerTenMb && checkLength <= 10) {
        setImgUpload((prev) => ({
          ...prev,
          files: [...imgUpload.files, ...newArrImg],
          img: [...imgUpload.img, ...e.target.files],
        }));
        setTypePost(TYPE_SHORT);
      } else {
        sizeImgBiggerTenMb && failImgThan10Mb();
        checkLength > 10 && failImgThan10();
        !checkImg && failImgInvalid();
      }
    } else if (!imageSelect && videoSelect) {
      let sizeImgBiggerTenGB = false;
      sizeImgBiggerTenGB = e.target.files[0].size > TEN_GB ? true : false;
      const checkTypes = e.target.files[0]['type'].includes('video');
      const checkLength = videoUpload.img.length + e.target.files.length;
      if (e.target.files[0]['type'] === 'video/x-ms-wmv') {
        const mediaInfoLib = await getInfo(e.target.files[0]);
        const durationVideo = +mediaInfoLib?.media?.track[0]?.Duration;
        if (checkTypes && !sizeImgBiggerTenGB && checkLength <= 1) {
          if (durationVideo <= 60) {
            setDurationVideo(durationVideo);
            setVideoUpload({
              files: URL.createObjectURL(e.target.files[0]),
              img: e.target.files,
            });
            setTypePost(TYPE_SHORT);
          } else {
            failShortVideo();
          }
        } else {
          sizeImgBiggerTenGB && failFileThan10Gb();
          !checkTypes && failVideoInvalid();
          checkLength > 1 && failVideoThan1();
        }
      } else {
        getDurationVideo(e.target.files[0], (duration) => {
          if (duration <= 60) {
            if (checkTypes && !sizeImgBiggerTenGB && checkLength <= 1) {
              setVideoUpload({
                files: URL.createObjectURL(e.target.files[0]),
                img: e.target.files,
              });
              setDurationVideo(duration);
              setTypePost(TYPE_SHORT);
              // type_post('short');
              // short_video(e.target.files[0]);
            } else {
              sizeImgBiggerTenGB && failFileThan10Gb();
              !checkTypes && failVideoInvalid();
              checkLength > 1 && failVideoThan1();
            }
          } else {
            failShortVideo();
          }
        });
      }
    }
  };

  const inputCamera = () => {
    const isDisable =
      videoUpload.img.length + imgUpload.img.length >= 10 ||
      (videoUpload.img.length > 0 && typePost === TYPE_SHORT) ||
      (imgUpload.img.length > 0 && typePost === TYPE_SHORT) ||
      !isMobile ||
      imgRecording?.length > 0;
    return (
      <div
        className={`inputFile ${isDisable && 'disableVideoInput'}`}
        onClick={() => refUploadCamera?.current?.click()}>
        <div className='btn-upload'>
          <UploadCameraIconV2 />
          <span>{t('Creator.PostArticle.camera')}</span>
        </div>
        <input
          ref={refUploadCamera}
          style={{ display: 'none' }}
          type='file'
          accept={acceptedImgVideoTypes.map((item) => item).join(',')}
          onChange={handleInputImg}
          disabled={isDisable}
          onClick={(e) => (e.target.value = null)}
          capture='camera'
          multiple
        />
      </div>
    );
  };
  const handleInputByCamera = (e) => {
    if (acceptedImageTypes.includes(e.target.files[0]['type'])) {
      let checkImg = true;
      let sizeImgBiggerTenMb = false;
      if (e.target.files[0].size > TEN_MB) sizeImgBiggerTenMb = true;
      if (!acceptedImageTypes.includes(e.target.files[0]['type'])) checkImg = false;

      if (e.target.files[0] && !sizeImgBiggerTenMb && checkImg) {
        setImgUpload((prev) => ({ ...prev, img: [...imgUpload.img, ...e.target.files] }));
        setTypePost(TYPE_NORMAL);
      } else {
        sizeImgBiggerTenMb && failImgThan10Mb();
        !checkImg && failImgInvalid();
      }
    } else {
      let sizeImgBiggerTenGB = false;
      sizeImgBiggerTenGB = e.target.files[0].size > TEN_GB ? true : false;
      const checkTypes = e.target.files[0]['type'].includes('video');
      const checkLength = videoUpload.img.length + e.target.files.length;
      if (e.target.files[0] && checkTypes && !sizeImgBiggerTenGB && checkLength <= 1) {
        setVideoUpload((prev) => ({ ...prev, img: e.target.files }));
        setTypePost(TYPE_NORMAL);
      } else {
        sizeImgBiggerTenGB && failFileThan10Gb();
        !checkTypes && failVideoInvalid();
        checkLength > 1 && failVideoThan1();
      }
    }
  };
  // END IMG VIDEO

  return (
    <>
      <div className='box-img-video'>
        <div className='list-img-video'>
          <div
            className={`list-item scroll-bar-custom-horizontal list-imgs ${
              videoUpload.img.length > 0 && 'has-video'
            }`}>
            <>
              {videoUpload.img.length > 0 && typePost === TYPE_NORMAL && (
                <>
                  {videoUpload.files ? (
                    <div
                      className={`box-item box-video1 ${
                        imgUpload.files.length > 0 ? '' : 'self-video'
                      }`}>
                      <VideoDetail src={videoUpload.files} className='video-item' />
                      <CloseIconV2
                        onClick={() => {
                          setVideoUpload({ img: [], files: null });
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      className={`box-item box-video1 ${
                        imgUpload.files.length > 0 ? '' : 'self-video'
                      }`}>
                      <VideoCustomDetail
                        src={`${linkS3Video}${decryptPath(videoUpload?.img[0]) + '#t=0.1'}`}
                        //thumbnail={thumbnail}
                        isLiveArchive
                        className='record-normal'
                        isDetailPost
                        handleNavigate={() => {}}
                      />
                    </div>
                  )}
                </>
              )}
              {imgUpload.files.length > 0 &&
                imgUpload.files.map((item, index) => {
                  return (
                    <>
                      <div
                        key={index}
                        className={`box-item ${
                          imgUpload.img.length > 1 ? 'box-img2' : 'box-img1'
                        }`}>
                        <ImgCustom src={item} />
                        <CloseIconV2 onClick={() => handleRemoveImg(index)} />
                      </div>
                    </>
                  );
                })}
            </>
          </div>

          <div
            className={`list-item list-videos ${
              videoUpload.img.length > 0 ? 'has-video-short' : ''
            }`}>
            {videoUpload.img.length > 0 && typePost === TYPE_SHORT && (
              <div className={`box-item box-video1 self-video`}>
                <VideoDetail src={videoUpload.files} className='video-item' />
                <CloseIconV2
                  onClick={() => {
                    setVideoUpload({ img: [], files: null });
                  }}
                />
              </div>
            )}
          </div>

          {/* show img type M3U8 */}
          {/* <div className={`list-item list-videos`}>
            {imgRecording?.length > 0 && (
              <div className={``}>
                <VideoCustomDetail
                  src={`${linkS3Video}${imgRecording}`}
                  //thumbnail={thumbnail}
                  className='record-normal'
                  isDetailPost
                  handleNavigate={() => {}}
                />
              </div>
            )}
          </div> */}

          <div className='box-btn-upload'>
            {inputImage()}
            {inputVideo()}
            {inputCamera()}
          </div>
        </div>
      </div>
    </>
  );
};

export default ListImgVideo;
