/* eslint-disable react-hooks/rules-of-hooks */
import useUserRole from 'hooks/useUserRole';
import SignUpUser from 'pages/creator/sign-up';
import IdolSignUpByFan from 'pages/creator/sign-up by-fan';
import ForgotPassword from 'pages/forgot-password';
import ResetPassword from 'pages/reset-password';
import SignIn from 'pages/sign-in';
import Authentication from 'pages/authentication';
import SignUpConfirm from 'pages/sign-in-twitter/signUpConfirm';
import SignUpTwSuccess from 'pages/sign-in-twitter/sinUpTwSuccess';
import { default as SignUp, default as SignUpAffiliator } from 'pages/sign-up/signUp';
import SignUpSuccess from 'pages/sign-up/signUpSuccess';
import VerifyEmail from 'pages/verify-email';
import WithDrawSuccess from 'pages/with-draw/WithDrawSuccess';
import React from 'react';
const { isIdol } = useUserRole();

export const NO_LOGIN_ROUTES = [
  { path: '/sign-in', element: <SignIn /> },
  { path: '/authentication', element: <Authentication /> },
  { path: '/forgot-password', element: <ForgotPassword /> },
  { path: '/reset-password/:token/:email', element: <ResetPassword /> },
  { path: '/sign-up', element: <SignUpUser /> },
  { path: '/sign-up-by-fan', element: isIdol ? <IdolSignUpByFan /> : <SignUp /> },
  { path: '/sign-up/success', element: <SignUpSuccess /> },
  { path: '/sign-up/confirm', element: <SignUpConfirm /> },
  { path: '/sign-up/tw/success', element: <SignUpTwSuccess /> },
  { path: '/sign-up?affiliator::affiliator_ref', element: <SignUpAffiliator /> },
  { path: '/v2/email-verification', element: <VerifyEmail /> },
  { path: '/with-draw/success', element: <WithDrawSuccess /> },
];
